import { styled } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"

const SquareIconButton = styled(IconButton)(({ theme }) => ({
  // Optionally, set the default shape (e.g., circular) if needed:
  borderRadius: "2px",
  // Change to a square on hover:
  "&:hover": {
    borderRadius: "2px",
  },
  // You can add any other styling you like here.
}))

export default SquareIconButton
