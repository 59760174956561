import React, { useState, useMemo } from "react"
import Controls from "../components/controls/Controls"
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
  Divider,
  LinearProgress,
  AppBar,
  Toolbar,
  Typography,
  Link,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Alert,
  AlertTitle,
  ListItemButton,
  Stack,
} from "@mui/material"
import { useHistory } from "react-router-dom"
import firebase from "firebase/compat/app"
import { FcGoogle } from "react-icons/fc"
import { AiOutlineMail } from "react-icons/ai"
import { FiLogIn } from "react-icons/fi"
import { useSnackbar } from "notistack"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import * as cloudFunctions from "./services/cloudFunctions"
import { processInvite } from "./services/cloudFunctions"
import * as Icons from "../icons"
import { version } from "../../package.json"
import LoginDialog from "../components/LoginDialog"
import ProgressBackdrop from "../components/ProgressBackdrop"
import { isEmailValid } from "./services/emailServices"
import * as stripeServices from "./services/stripeServices"
import _ from "lodash"
import db from "../Firestore"
import AcceptEULADialog from "../components/AcceptEULADialog"
import Logo from "../components/Logo"
import * as authenticationServices from "./services/authenticationServices"
import { spacing } from "./services/styleServices"

const styles = {
  pageContent: {
    marginBottom: spacing(2),
    padding: spacing(2),
    minWidth: 300,
  },
  leftPadded: {
    marginLeft: spacing(2),
  },
  versionBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "flex-start",
    width: "100%",
    maxWidth: 400,
  },
  footerText: {
    marginRight: spacing(2),
  },
  gridContainer: {
    display: "flex",
    alignContent: "flex-start",
  },
  link: {
    cursor: "pointer",
  },
  listItemIcon: {
    marginTop: "4px",
  },
  centreCaption: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}

function SignIn() {
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const [isShowProgress, setShowProgress] = useState(true)

  const [isShowOTP, setShowOTP] = useState(false)

  const [isEnterEmailDialogOpen, setEnterEmailDialogOpen] = useState(false)

  const [signInInfo, setSignInInfo] = useState({
    accountId: undefined,
    lastSignInTime: undefined,
  })

  const [invites, setInvites] = useState([])

  // Does the user need to set up billing?
  const [requiresBillingSetup, setRequiresBillingSetup] = useState(false)

  const [daysTilTrialEnd, setDaysTilTrialEnd] = useState()

  const [isSecurityError, setSecurityError] = useState(false)

  const [logs, setLogs] = useState([])

  const isGoogleEmail = useMemo(
    () => signInInfo?.user?.email?.toLowerCase().includes("@gmail.com"),
    [signInInfo]
  )

  const [plan, setPlan] = useState()

  const [loginInputs, setLoginInputs] = useState({})

  const [user, setUser] = useState()

  const [emailErrorMessage, setEmailErrorMessage] = useState("")

  const [email, setEmail] = useState("")

  const hasPassword = useMemo(
    () => authenticationServices.isPasswordProviderFound(user),
    [user]
  )

  const [acceptEULAOpen, setAcceptEULAOpen] = useState(false)

  //const [isEULAAccepted, setEULAAccepted] = useState(false)

  // If this is a new user, they will be prompted to set a password -- if it's a non-Google user.
  const [changePasswordValues, setChangePasswordValues] = useState({
    newPassword: "",
  })

  const hasProvidedNewPasswordValues = useMemo(() => {
    return changePasswordValues.newPassword !== ""
  }, [changePasswordValues])

  const indeterminateSignInStatus = {
    title: "Signing in...",
    severity: "info",
    message: "",
    canSignUp: false,
    canSignIn: true,
    canContinue: false,
    checkForInvite: false,
    canSignOut: false,
  }

  const inProgressSignInStatus = {
    title: "In progress...please wait",
    severity: "info",
    message: "",
    canSignUp: false,
    canSignIn: false,
    canContinue: false,
    checkForInvite: false,
    canSignOut: false,
  }

  const [signInStatus, setSignInStatus] = useState(indeterminateSignInStatus)

  // Watch for invites

  useEffect(() => {
    let unsub
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user)
        user.getIdTokenResult().then((token) => {
          const plan = token.claims.plan
          setPlan(plan)

          if (user.emailVerified) {
            unsub = db
              .collection("invites")
              .where("email", "==", user.email)
              .where("type", "==", "client")
              .onSnapshot(
                (querySnapshot) => {
                  const newInvites = querySnapshot.docs.map((doc) => {
                    return { id: doc.id, ...doc.data() }
                  })

                  console.log(`found ${newInvites.length} invites`)
                  setInvites(newInvites)
                },
                (error) => {
                  console.log("%cFireBase error", "color:red", { user, error })
                  //enqueueSnackbar(error.message, { variant: "error" })
                  setShowProgress(false)
                  //setSecurityError(true)
                }
              )
          }
        })
      }
    })

    return unsub
  }, [])

  const isValidToken = (token) => {
    return (
      token &&
      token.claims &&
      token.claims.email_verified &&
      token.claims.roles &&
      token.claims.roles.length > 0
    )
  }

  const TRIAL_DAYS = 30

  const evaluateClaimsStatus = (claimsStatus) => {
    const trialDaysLeft =
      TRIAL_DAYS - Math.floor(claimsStatus.data.days_since_account_creation)

    setDaysTilTrialEnd(trialDaysLeft)

    const isPastTrialPeriod = trialDaysLeft < 0

    setRequiresBillingSetup(!claimsStatus.data.billing_setup)

    console.log("Check claims for billing status", {
      trialDaysLeft,
      isPastTrialPeriod,
      billingSetup: claimsStatus.data.billing_setup,
      claimsStatus,
    })
  }

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdTokenResult(true).then(async (token) => {
          console.log("existing token", token)

          if (isValidToken(token)) {
            console.log("%chas existing claims", "color:lightgreen")
            setSignInInfo({
              accountId: token.claims.account_id,
              lastSignInTime: user.metadata.lastSignInTime,
              user: user,
            })

            // Update claims in case permissions have changed.
            // Doesn't matter if slight delay in claims being updated.
            // Just call this async, i.e. don't 'await'
            console.log("%crefreshing claims", "color:lightgreen")
            const result = await cloudFunctions.obtainCustomClaims()
            console.log("Checked claims", { result })

            evaluateClaimsStatus(result)
          } else {
            console.log("Check claims")
            const result = await cloudFunctions.obtainCustomClaims()
            console.log("Checked claims", { result })

            evaluateClaimsStatus(result)

            user.getIdTokenResult(true).then(async (token) => {
              setSignInInfo({
                accountId: token.claims.account_id,
                lastSignInTime: user.metadata.lastSignInTime,
                user: user,
              })

              console.log("account type", token.claims.account_type)
            })
          }
        })
      } else {
        console.log("[authStateChanged] no user")
        setSignInInfo({
          accountId: undefined,
          lastSignInTime: undefined,
          user: undefined,
        })
      }
    })
    //setCheckedClaims(true)

    return unsub
  }, [])

  const addLog = async (log) => {
    setLogs([...logs, log])
  }

  const handleSignUp = () => {
    history.push("/SignUp")
  }

  const handleSignIn = () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

    setSignInStatus(indeterminateSignInStatus)
    setShowProgress(true)

    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result) => {
        setSignInStatus(inProgressSignInStatus)

        console.log("[handleSignIn]", result)
        console.log("[handleSignIn]", "uid", result.user.uid)
        result.user.getIdTokenResult(true).then(async (token) => {
          console.log("[handleSignIn]", { token })

          console.log(
            "[handleSignIn]",
            "has account id?",
            { token },
            token.claims.hasOwnProperty("account_id")
          )

          console.log(result, token)

          setSignInInfo({
            accountId: token.claims.account_id,
            lastSignInTime: result.user.metadata.lastSignInTime,
            user: result.user,
          })
        })
      })
  }

  const handleGetStarted = () => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdTokenResult(true)
        .then((token) => {
          console.log(token.claims.roles)
          // if (
          //     token.claims.roles &&
          //     (token.claims.roles.includes(Roles.PROJECT_ADMIN) ||
          //         token.claims.roles.includes(Roles.PROJECT_USER))
          // ) {
          //     history.push("/projects")
          // } else {
          history.push("/dashboard")
          //}
        })
    }
  }

  const handleSignOut = () => {
    console.log("handleSignOut")
    setInvites([])
    setSignInInfo(indeterminateSignInStatus)
    setUser(null)
    firebase
      .auth()
      .signOut()
      .then(async (result) => {
        //setUserDetails(emptyUserDetails)
        await updateSignInStatus("from handleSignOut", { signInInfo })
      })
  }

  const handleEmailOTPSignIn = () => {
    if (!email) {
      enqueueSnackbar("Please enter your email address", { variant: "info" })
    } else {
      if (isEmailValid(email)) {
        getEmailLoginInputs(email).then((result) => {
          console.log("%clogin inputs", "color:yellow", result)
          setEmailErrorMessage("")
          setLoginInputs(result)
          setShowOTP(true)
        })
      } else {
        setEmailErrorMessage("Please enter a valid email address")
      }
    }
  }

  const getEmailLoginInputs = async () => {
    if (email === "") {
      return {}
    }
    const promises = [
      cloudFunctions.getUserAuthenticationMethods({ email: email }),
    ]

    const results = await Promise.all(promises)

    console.log("results", results)

    const loginInputs = {
      providerIds: results[0].data.providers,
      emailDefinedInGoogle: results[0].data.userFound,
      //usedQrCodeLogin: results[1].data,
      isQrCodeSecretDefined: results[0].data.isQrCodeSecretDefined,
    }

    console.log("loginInputs", { loginInputs })
    return loginInputs
  }

  const getClaims = async (user) => {
    const token = await user.getIdTokenResult(false)

    if (token.claims.hasOwnProperty("account_id")) {
      return token.claims
    } else {
      // This updates the claims, even though the results aren't used by this function

      console.log("getClaims", "no account id in JWT, obtaining custom claims")
      const claimsUpdateStatus = await cloudFunctions.obtainCustomClaims()
      console.log("got claims", claimsUpdateStatus)

      const updatedToken = await user.getIdTokenResult(true)
      return updatedToken.claims
    }
  }

  const [loadState, setLoadState] = useState()

  useEffect(() => {
    const newLoadState = { signInInfo }

    const isLoadStateChanged = !_.isEqual(loadState, newLoadState)

    setLoadState(newLoadState)

    if (isLoadStateChanged) {
      console.log("load state changed", { newLoadState, loadState, signInInfo })

      updateSignInStatus("signInInfo useEffect", { signInInfo })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInInfo])

  const updateSignInStatus = async (source, { signInInfo }) => {
    console.log("[updateSignInStatus] BEGIN. source", { source, signInInfo })

    const notSignedInStatus = {
      title: "Sign In",
      severity: "info",
      message: "",
      options: [],
      canSignUp: false,
      canSignIn: true,
      canContinue: false,
      checkForInvite: false,
      canSignOut: false,
    }

    setShowProgress(true)

    if (signInInfo.user !== undefined) {
      console.log(
        "%c[updateSignInStatus] check if user record exists",
        "color:yellow",
        signInInfo
      )

      let userRecFound

      try {
        userRecFound = await cloudFunctions.checkUserExists()
        console.log("user rec found?", userRecFound)
      } catch (e) {
        console.log("%cerror checking user exists", "color:red", {
          e,
          msg: e.message,
        })

        if (e.code === "appCheck/fetch-status-error") {
          setSignInStatus(notSignedInStatus)
          setShowProgress(false)
          return
        }
      }

      let claims = {}

      if (userRecFound) {
        claims = await getClaims(signInInfo.user)
      }

      const email = signInInfo.user.email

      console.log("[updateSignInStatus]", {
        hasAccountId: claims.hasOwnProperty("account_id"),
        userRecFound: userRecFound,
      })

      if (claims.hasOwnProperty("account_id") && userRecFound) {
        const accountId = claims.account_id

        dataServices
          .getAccountById(accountId)
          .then((account) => {
            console.log("account", account)
            setSignInStatus({
              title: "Signed in",
              severity: "success",
              message: (
                <>
                  <strong>
                    {account.name} {email}
                  </strong>
                </>
              ),
              options: [],
              canSignUp: false,
              canSignIn: false,
              canContinue: true,
              checkForInvite: false,
              canSignOut: true,
            })
            setShowProgress(false)
          })
          .catch((error) => {
            console.log("error", { error })
            //enqueueSnackbar(`${error.code}: ${error.message}`, { variant: "error" })
            setSecurityError(true)
          })
      } else {
        const options = []

        if (invites.length === 0) {
          options.push(
            <ListItem key="await-invite" alignItems="flex-start">
              <ListItemIcon sx={styles.listItemIcon}>
                <Icons.EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary="Await an invite to join"
                secondary="Your account admin will create an Invite email to join an existing account"
              />
            </ListItem>
          )
        }

        console.log("%cAdding sign up option", "color:yellow")
        options.push(
          <ListItemButton
            key="sign-up"
            alignItems="flex-start"
            onClick={handleSignUp}
          >
            <ListItemIcon sx={styles.listItemIcon}>
              <Icons.CreateIcon />
            </ListItemIcon>
            <ListItemText
              primary="Sign Up"
              secondary="Create a new account for your organisation. Note we cannot merge accounts."
            />
          </ListItemButton>
        )

        setSignInStatus({
          title: "Email Verified",
          severity: "info",
          message: (
            <Typography component={"span"}>
              <strong>{email}</strong> is verified.
            </Typography>
          ),
          options: options,
          canSignUp: true,
          canSignIn: false,
          canContinue: false,
          checkForInvite: true,
          canSignOut: true,
        })

        setShowProgress(false)
      }
    } else if (
      signInInfo.accountId === "" ||
      signInInfo.accountId === undefined
    ) {
      console.log(`[updateSignInStatus] ${source}`, "Not signed In", {
        signInInfo,
      })

      setSignInStatus(notSignedInStatus)
      setShowProgress(false)
    } else {
      console.log("[updateSignInStatus]", "Otherwise...", { signInInfo })
    }
  }

  const handleClickAcceptInvite = (inviteId) => {
    console.log("isGoogleEmail", {
      isGoogleEmail,
      providers: user.providerData,
    })

    setAcceptEULAOpen(true)
  }

  const handleProcessInvite = async (inviteId) => {
    console.log("accept invite", { hasPassword, hasProvidedNewPasswordValues })

    // Verify that if it's a non-Google user that they have a password set
    if (!isGoogleEmail && !hasPassword && !hasProvidedNewPasswordValues) {
      enqueueSnackbar(
        "You must set a password before you can accept an invite",
        {
          variant: "error",
        }
      )
      return
    }

    if (!isGoogleEmail && hasProvidedNewPasswordValues) {
      console.log("hasProvidedNewPasswordValues", hasProvidedNewPasswordValues)

      await authenticationServices.setPassword({
        email: signInInfo.user.email,
        oldPassword: "",
        newPassword: changePasswordValues.newPassword,
      })
    }

    setShowProgress(true)

    enqueueSnackbar("Accepting invite...", { variant: "info" })

    const inviteResult = await processInvite()

    console.log("[authStateChanged] process invite (2)", {
      inviteResult,
    })

    if (inviteResult.data.invite_processed === true) {
      await updateSignInStatus("from accept invite", { signInInfo })
      enqueueSnackbar(`Success`, { variant: "success" })
    } else {
      enqueueSnackbar(inviteResult.data.response, { variant: "error" })
    }

    firebase
      .auth()
      .currentUser.getIdTokenResult(true)
      .then((token) => {
        console.log("token", token)
        const accountId = token.claims.account_id
        stripeServices.checkAndUpdateSubscriptionCounts(accountId)
      })

    setShowProgress(false)
  }

  const handleSendResetPasswordLink = async (email) => {
    setEnterEmailDialogOpen(false)
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((result) => {
        console.log("[handleResetPassword]", result)
        enqueueSnackbar("Reset password link sent to email", {
          variant: "success",
        })
      })
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={styles.title} component={"span"}>
            Sign In
          </Typography>
        </Toolbar>
      </AppBar>

      <ProgressBackdrop open={isShowProgress} />

      <EnterEmailDialog
        open={isEnterEmailDialogOpen}
        setOpen={setEnterEmailDialogOpen}
        handleSendResetPasswordLink={handleSendResetPasswordLink}
      />

      {isShowOTP && (
        <LoginDialog
          open={isShowOTP}
          setOpen={setShowOTP}
          loginInputs={loginInputs}
          email={email}
        />
      )}

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Logo />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ maxWidth: 400 }}>
          <Paper sx={styles.pageContent}>
            <Grid container direction="column" sx={styles.gridContainer}>
              {isShowProgress && <LinearProgress />}

              <Grid container spacing={2} direction="column">
                <Grid item key="alert">
                  <Alert severity={signInStatus.severity}>
                    <AlertTitle>{signInStatus.title}</AlertTitle>
                    {signInStatus.message}
                  </Alert>
                </Grid>

                <Grid item key="invites-list">
                  {invites.map((invite) => (
                    <Box key={invite.id}>
                      <ListItemButton
                        alignItems="flex-start"
                        onClick={() => handleClickAcceptInvite(invite.id)}
                      >
                        <ListItemIcon sx={styles.listItemIcon}>
                          <Icons.InviteIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Accept Invite: ${
                            invite.account_name || ""
                          }`}
                          secondary="Accept the invite to this account"
                        />
                      </ListItemButton>

                      <AcceptEULADialog
                        open={acceptEULAOpen}
                        handleAccept={() => {
                          setAcceptEULAOpen(false)
                          //setEULAAccepted(true)
                          handleProcessInvite(invite.id)
                          console.log("accepted")
                        }}
                        handleCancel={() => setAcceptEULAOpen(false)}
                      />
                    </Box>
                  ))}
                </Grid>

                {signInStatus.options && signInStatus.options.length > 0 && (
                  <Grid item>
                    <List>{signInStatus.options.map((option) => option)}</List>
                  </Grid>
                )}

                {signInStatus.canSignIn && (
                  <>
                    <Grid item>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Existing Organisations
                      </Typography>
                      <Typography variant="caption" color="primary">
                        You wish to login to your account, or be invited to join
                        an existing account for your organisation.
                      </Typography>
                    </Grid>
                    <Grid item key="google-sign-in-button">
                      <Button
                        text="Sign In with Google"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        onClick={handleSignIn}
                        disabled={!signInStatus.canSignIn}
                        startIcon={<FcGoogle />}
                      >
                        Sign In with Google
                      </Button>
                    </Grid>

                    <Divider sx={{ marginTop: "10px" }} />

                    <Grid item>
                      <Controls.TextInput
                        variant="outlined"
                        value={email}
                        label="Email address"
                        helperText="Access your organisation's existing account"
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleEmailOTPSignIn()
                          }
                        }}
                      />
                    </Grid>

                    {emailErrorMessage !== "" && (
                      <Grid item>
                        <Typography variant="caption" color={"#f20000"}>
                          {emailErrorMessage}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item key="email-signin-button">
                      <Button
                        text="Continue"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        onClick={() => handleEmailOTPSignIn()}
                        disabled={!signInStatus.canSignIn}
                        startIcon={<AiOutlineMail />}
                      >
                        Sign In with Email
                      </Button>
                    </Grid>
                  </>
                )}

                {signInStatus.canContinue && (
                  <Grid item key="get-started-button">
                    <Button
                      onClick={handleGetStarted}
                      fullWidth={true}
                      startIcon={<FiLogIn />}
                      variant="contained"
                      size="small"
                      disabled={!signInStatus.canContinue}
                    >
                      Get Started
                    </Button>

                    <BillingStatus
                      daysTilTrialEnd={daysTilTrialEnd}
                      requiresBillingSetup={requiresBillingSetup}
                      plan={plan}
                    />
                  </Grid>
                )}
              </Grid>

              {!user && (
                <>
                  <Grid item>
                    <Box sx={{ marginTop: "15px", marginBottom: "15px" }}>
                      <Divider />
                    </Box>
                  </Grid>

                  <Grid item sx={{ marginBottom: "15px" }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      New organisation
                    </Typography>
                    <Typography variant="caption" color="primary">
                      You wish to create a new blank account for your
                      organisation. You can then later invite other users from
                      your organisation if required.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      text="Create a new account"
                      onClick={handleSignUp}
                      fullWidth={true}
                      variant="outlined"
                      size="small"
                    >
                      Create a new organisation
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item>
              <Box sx={{ marginTop: "15px", marginBottom: "15px" }}>
                <Divider />
              </Box>
            </Grid>

            <Grid>
              <Box sx={styles.centreCaption}>
                <Typography variant="caption">
                  Lost your password?{" "}
                  <Link
                    sx={styles.link}
                    onClick={() => setEnterEmailDialogOpen(true)}
                  >
                    Reset password
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.leftPadded}>
          {signInStatus.canSignOut && (
            <Typography variant="caption" component={"span"}>
              <Link color="primary" sx={styles.link} onClick={handleSignOut}>
                Sign Out
              </Link>
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Stack gap={1}>
          <Typography variant="caption">AIM is powered by OpenAI</Typography>
          <Box sx={{ marginLeft: "10px" }}>
            <img src="/openai-lockup.svg" alt="OpenAI" width="100px" />
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.versionBox}>
          <Box>
            <Typography
              variant="caption"
              sx={styles.footerText}
              component={"span"}
            >
              v{version}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.versionBox}>
          <Box>
            <Link
              href="https://architectureinmotion.com.au/aim-versions"
              target="_aimVersions"
              underline="none"
            >
              <Typography
                variant="caption"
                sx={styles.footerText}
                component={"span"}
              >
                Generative AI using OpenAI
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.versionBox}>
          <Box>
            <Link
              href="https://architectureinmotion.com.au/resources/"
              target="_resources"
              underline="none"
            >
              <Typography
                variant="caption"
                sx={styles.footerText}
                component={"span"}
              >
                AIM Resources
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.versionBox}>
          <Box>
            <Link
              href="https://architectureinmotion.com.au/generative-ai-samples/"
              target="_generativeAISamples"
              underline="none"
            >
              <Typography
                variant="caption"
                sx={styles.footerText}
                component={"span"}
              >
                Generative AI samples
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.versionBox}>
          <Box>
            <Link
              href="https://www.youtube.com/channel/UCXrhfU6BMBPw7-6jxeV7J1w"
              target="_youtube"
              underline="none"
            >
              <Typography
                variant="caption"
                sx={styles.footerText}
                component={"span"}
              >
                YouTube
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.versionBox}>
          <Box>
            <Link
              href="https://www.architectureinmotion.com.au/aim-jobs-to-be-done"
              target="_jobsToBeDone"
              underline="none"
            >
              <Typography
                variant="caption"
                sx={styles.footerText}
                component={"span"}
              >
                How does AIM help you?
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Box sx={styles.versionBox}>
          <Box>
            <Link
              href="https://www.architectureinmotion.com.au"
              target="_aimMain"
              underline="none"
            >
              <Typography
                variant="caption"
                sx={styles.footerText}
                component={"span"}
              >
                Support and User Guide
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const BillingStatus = (props) => {
  const { daysTilTrialEnd, requiresBillingSetup, plan } = props

  return (
    <>
      {requiresBillingSetup && daysTilTrialEnd <= 0 && (
        <Box sx={{ marginTop: "10px" }}>
          <Alert severity="warning">
            <Typography variant="caption">
              The trial period has ended. Billing setup is available under the
              top right user icon menu. The account is now read-only.
            </Typography>
          </Alert>
        </Box>
      )}

      {daysTilTrialEnd > 0 &&
        requiresBillingSetup &&
        plan &&
        plan === "business" && (
          <Box sx={{ marginTop: "10px" }}>
            <Alert severity="info">
              <Typography variant="caption">
                Trial period ends in {daysTilTrialEnd} days. You can enter
                payment details any time and billing will only occur from the
                end of the trial period. Billing setup is available under the
                top right user icon menu.
              </Typography>
            </Alert>
          </Box>
        )}
    </>
  )
}

const EnterEmailDialog = (props) => {
  const { open, setOpen, handleSendResetPasswordLink } = props

  const [email, setEmail] = useState("")

  const handleClickOK = (email) => {
    if (isEmailValid(email)) {
      handleSendResetPasswordLink(email)
    }
  }

  useEffect(() => {
    if (open) {
      setEmail("")
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Enter Email</DialogTitle>
      <DialogContent>
        <DialogContentText>Receive a password reset email</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={email}
          fullWidth
          variant="outlined"
          size="small"
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClickOK(email)} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SignIn
