import { Box, Typography } from "@mui/material"
import { useMemo } from "react"
import { getPropTypeCount } from "../pages/services/modelEditServices"

const ModelEditLegend = ({ currentView, currentShader }) => {
    // Count how often the current shader is used in currentView
    const legendCount = useMemo(() => {
        return getPropTypeCount(currentShader?.property || "", currentView.elements)
    }, [currentView, currentShader])

    const usedShaderValues = useMemo(() => {
        if (currentShader && currentView) {
            const elementsWithProp = currentView.elements.filter((e) =>
                e.props.some((p) => p.name.toLowerCase() === currentShader.property.toLowerCase())
            )
            const result = currentShader.config.colors.filter((c) =>
                elementsWithProp.some((e) =>
                    e.props.some(
                        (p) =>
                            p.name.toLowerCase() === currentShader.property.toLowerCase() &&
                            p.value.toString().toLowerCase() === c.value.toString().toLowerCase()
                    )
                )
            )
            return result
        }
    }, [currentShader, currentView])

    return (
        <>
            {usedShaderValues && usedShaderValues.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        marginLeft: "20px",
                        marginTop: "20px",
                        border: "1px solid #ccc",
                        padding: "10px",
                    }}
                >
                    {usedShaderValues &&
                        usedShaderValues
                            .sort((a, b) => a.value.localeCompare(b.value))
                            .map((c) => (
                                <Box
                                    key={c.value}
                                    sx={{ display: "flex", flexDirection: "row", gap: "5px" }}
                                >
                                    <Box>
                                        <Typography variant="caption">{c.value}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "30px",
                                            height: "20px",
                                            backgroundColor: c.color,
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography variant="caption">
                                            {legendCount && legendCount[c.value.toLowerCase()]}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                </Box>
            )}
        </>
    )
}

export default ModelEditLegend
