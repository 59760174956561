import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  colors,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import DoneIcon from "@mui/icons-material/Done"
import SwapVertIcon from "@mui/icons-material/SwapVert"
import { useEffect, useMemo, useState } from "react"
import Controls from "./controls/Controls"
import * as palette from "./symbols/palette"
import { aiColor } from "../pages/services/colorServices"
import * as modelServices from "../pages/services/modelServices"
import { getSuggestedViewPrompt } from "../pages/services/chatGenerationServices"
import ModelEditElementTypes from "./ModelEditElementTypes"
import ClearIcon from "@mui/icons-material/Clear"
import PushPinIcon from "@mui/icons-material/PushPin"

const ModelEditViewSuggestionCard = ({
  suggestion,
  setSuggestion,
  viewSet,
  views,
  prompts,
  setPrompts,
  handleCreateViewFromSuggestion,
  handleDeleteViewSuggestion,
  generatingContentMessage,
  setGeneratingContentMessage,
  handleTogglePinSuggestedView,
  roles,
  canAddView,
}) => {
  const elementTypeNames = useMemo(() => {
    return suggestion.element_types.map((level) => level.type) || []
  }, [suggestion])

  const [isBusy, setBusy] = useState(false)

  // show pin icon if suggested view is already pinned, or mouse is over the card
  const [isShowPinIcon, setShowPinIcon] = useState(
    suggestion.pinned //&& !isGenerating
  )

  const [isPinHovering, setPinHovering] = useState(false)

  const pinIconStyle = useMemo(() => {
    if (suggestion?.pinned) {
      return { color: colors.grey[600] }
    }

    if (isPinHovering) {
      return { color: colors.grey[200] }
    }

    return {}
  }, [suggestion, isPinHovering])

  const handleCreatePrompts = () => {
    setGeneratingContentMessage("Generating prompts...")
    setBusy(true)

    const funcs = suggestion.element_types.map((level, index) => {
      const elementType = level.type
      return getSuggestedViewPrompt({
        overview: viewSet.overview,
        purpose: viewSet.purpose,
        question: suggestion.question,
        elementType: elementType,
        parentType:
          level > 0 ? suggestion.element_types[index - 1].type : undefined,
        roles: roles,
      })
    })

    Promise.all(funcs)
      .then((prompts) => {
        console.log("prompts", prompts)
        setPrompts(prompts)
      })
      .then(() => {
        setGeneratingContentMessage(undefined)
        setBusy(false)
      })
  }

  useEffect(() => {
    //setShowPinIcon(isBusy || isGenerating ? false : question?.pinned)
    setShowPinIcon(isBusy ? false : suggestion?.pinned)
  }, [suggestion, isBusy])

  const isGenerating = useMemo(() => {
    return Boolean(generatingContentMessage) && isBusy
  }, [generatingContentMessage, isBusy])

  const handleClearPrompts = () => {
    setPrompts([])
  }

  // Change the array order of elements in these suggestion properties:
  // 1. element_types
  // 2. prompts
  // 3. recipe.levels
  // Note that using reverse() only works because there are 2 array elements max
  const handleSwapOrder = () => {
    const newSuggestion = {
      ...suggestion,
      element_types: suggestion.element_types.reverse(),
    }

    if (suggestion.prompts) {
      newSuggestion.prompts = suggestion.prompts.reverse()
    }

    setSuggestion(newSuggestion)
  }

  return (
    <Card
      sx={{ width: "400px", display: "flex", flexDirection: "column" }}
      onMouseEnter={() => {
        setPinHovering(true)
        if (!isBusy) {
          setShowPinIcon(true)
        }
      }}
      onMouseLeave={() => {
        setPinHovering(false)
        if (!isBusy) {
          setShowPinIcon(suggestion.pinned)
        }
      }}
    >
      <CardHeader
        title={
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="body1" fontWeight={"bold"}>
              {suggestion.name}
            </Typography>
            {views.find((v) => v.name === suggestion.name) && (
              <Box sx={{ ml: "auto" }}>
                <Tooltip title="Check the My Views tab for the view created using this View Recipe">
                  <DoneIcon color="success" />
                </Tooltip>
              </Box>
            )}
          </Box>
        }
        subheader={
          <Typography variant="caption" color="text.secondary">
            Elements:{"   "}
            {suggestion.element_types.map((level, index) => (
              <span key={level.type}>
                <Tooltip
                  title={
                    palette.getElementType(level?.type)?.description ||
                    "Open question"
                  }
                >
                  <Typography variant="caption" color="text.secondary">
                    {palette.formatLabel(level.type)}
                  </Typography>
                </Tooltip>
                {index < suggestion.element_types.length - 1 && ", "}
              </span>
            ))}
          </Typography>
        }
        sx={{ padding: "10px" }}
      />
      <CardContent sx={{ padding: "10px", flex: "1" }}>
        <Stack direction="column" gap={2}>
          {elementTypeNames && (
            <SuggestedViewElementTypesSummary
              suggestedElementTypes={elementTypeNames}
            />
          )}
          <Box>
            <Typography variant="body2" fontWeight={"bold"}>
              Rationale
            </Typography>
            <Typography variant="body2">{suggestion.reason}</Typography>
          </Box>
          {suggestion.question && (
            <Box>
              <Typography variant="body2" fontWeight={"bold"}>
                Created from question
              </Typography>
              <Typography variant="body2">{suggestion.question}</Typography>
            </Box>
          )}
          <Box>
            <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
              <Typography variant="body2" color="text.secondary">
                Element Types
              </Typography>
              {suggestion.element_types.length > 1 && prompts.length === 0 && (
                <>
                  <Box sx={{ flexGrow: 1 }} />
                  <Tooltip title="Swap Element Order">
                    <span>
                      <IconButton
                        onClick={handleSwapOrder}
                        disabled={Boolean(generatingContentMessage)}
                      >
                        <SwapVertIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </Stack>
            <Stack sx={{ ml: "5px", mt: "15px" }} direction="column" gap={2}>
              {suggestion.element_types.map((level) => (
                <Box key={level.type}>
                  <Typography variant="body2" fontWeight={"bold"}>
                    {palette.formatLabel(level.type)}
                  </Typography>
                  <Typography variant="body2">{level.reason}</Typography>
                </Box>
              ))}
            </Stack>
          </Box>
          {prompts.length > 0 && (
            <Box>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Typography variant="body2" color="text.secondary">
                  Prompts
                </Typography>

                {prompts.length > 0 && (
                  <>
                    <Box sx={{ flexGrow: 1 }} />
                    <Tooltip title="Clear Prompts">
                      <span>
                        <IconButton
                          onClick={handleClearPrompts}
                          disabled={Boolean(generatingContentMessage)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
              </Stack>

              <Stack sx={{ ml: "5px", mt: "15px" }} direction="column" gap={2}>
                {prompts.map((p) => (
                  <Box key={p.elementType}>
                    <Typography variant="body2" fontWeight={"bold"}>
                      {palette.formatLabel(p.elementType)}
                    </Typography>
                    <Typography variant="body2">{p.prompt}</Typography>

                    {p.referenced_views?.length > 0 && (
                      <Stack direction="column" sx={{ marginTop: "10px" }}>
                        <Typography>Useful views to reference:</Typography>
                        <Stack direction="column">
                          {p.referenced_views.map((rv, index) => (
                            <Typography key={rv}>
                              {index + 1}. {rv}
                            </Typography>
                          ))}
                        </Stack>
                      </Stack>
                    )}
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
          {isGenerating && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                mb: "20px",
              }}
            >
              <CircularProgress size={20} sx={{ ml: "10px" }} />
              <Typography variant="caption" sx={{ ml: "10px" }}>
                Generating prompts...
              </Typography>
            </Box>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", width: "100%" }}
        >
          {prompts.length === 0 && (
            <Controls.Button
              text="Create Prompts"
              onClick={handleCreatePrompts}
              tooltip="Generate suggested prompts for this view"
              buttonStyle={{ backgroundColor: aiColor, color: "#fff" }}
              disabled={Boolean(generatingContentMessage)}
            />
          )}

          <Controls.Button
            text="Create View"
            onClick={() => handleCreateViewFromSuggestion({ suggestion })}
            tooltip="Create a view based on the suggested prompts"
            buttonStyle={{ backgroundColor: aiColor, color: "#fff" }}
            disabled={
              Boolean(generatingContentMessage) ||
              prompts.length === 0 ||
              canAddView === false
            }
          />

          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Keep this question when you regenerate new questions">
            <span>
              <IconButton
                onClick={() => {
                  handleTogglePinSuggestedView(suggestion)
                }}
              >
                {isShowPinIcon && <PushPinIcon sx={pinIconStyle} />}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete this View Recipe">
            <IconButton
              onClick={() => handleDeleteViewSuggestion(suggestion.name)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </CardActions>
    </Card>
  )
}

const SuggestedViewElementTypesSummary = ({ suggestedElementTypes }) => {
  // An array of elements, where each element has a type + count attribute

  //console.log("suggestedElementTypes", suggestedElementTypes)
  const elementTypes = useMemo(() => {
    return (
      suggestedElementTypes?.map((elementType) => {
        const typeDef = palette.getElementType(elementType)
        return {
          type: typeDef.index,
          count: 1,
          symbol: modelServices.getPaletteItem(
            typeDef.name,
            "suggested element types"
          ),
          label: palette.formatLabel(typeDef.name),
        }
      }) || []
    )
  }, [suggestedElementTypes])

  return (
    <Box>
      <ModelEditElementTypes elementTypes={elementTypes} />
    </Box>
  )
}

export default ModelEditViewSuggestionCard
