import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Box } from "@mui/system"

const AcceptEULADialog = ({ open, handleAccept, handleCancel }) => {
    const [scroll, setScroll] = useState("paper")

    const descriptionElementRef = React.useRef(null)
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    const [scrolledToEnd, setScrolledToEnd] = useState(false)

    useEffect(() => {
        if (open) {
            setScrolledToEnd(false)
        }
    }, [open])

    const handleScroll = (e) => {
        const isScrolledToEnd =
            Math.abs(e.target.scrollTop + e.target.clientHeight - e.target.scrollHeight) <= 2

        setScrolledToEnd(isScrolledToEnd)
    }

    const eula = [
        { text: `End User Licence Agreement and Terms and Conditions` },
        { text: "The basics", indent: 1, label: "1." },
        {
            text: "“AIM” means Agile EA Services Pty Limited ACN 657 511 718, which operates the AIM application, our related bodies corporate. This document explains how the agreement is made up and sets out the terms of our agreement with you.",
            indent: 2,
            label: "(a)",
        },
        {
            text: "AIM is a web application providing support to business on IT investment lifecycle.",
            indent: 2,
            label: "(b)",
        },
        {
            text: "Your use and/or purchase of AIM's products, services and websites (including affiliated websites and web and mobile applications (the “Services”) is subject to the terms of a legal agreement between you and AIM.",
            indent: 2,
            label: "(c)",
        },
        {
            text: "Unless otherwise agreed in writing with AIM, your agreement with AIM™ will always include the terms and conditions set out in this document (“Main Terms”).",
            indent: 2,
            label: "(d)",
        },
        {
            text: "Your agreement with AIM will also include the terms of any additional notices we send you or post on any website owned or operated by AIM applicable to the Services, in addition to the Main Terms, together with any agreement we enter into with you in relation to any Services (“Further Terms”).",
            indent: 2,
            label: "(e)",
        },
        {
            text: "The Main Terms, together with the Further Terms and the AIM Privacy Policy form a legally binding agreement between you and AIM in relation to your use of the Services. This agreement (embodying all of these terms) is referred to below as the “Terms”.",
            indent: 2,
            label: "(f)",
        },
        {
            text: "If there is any difference between what the Further Terms say and what the Main Terms say in relation to a Service, then the Further Terms shall prevail in relation to a specific Service.",
            indent: 2,
            label: "(g)",
        },
        {
            text: "The Terms will apply to all users of the Services, regardless of whether you have a separate agreement with AIM.",
            indent: 2,
            label: "(h)",
        },
        {
            text: "You acknowledge that AIM may will (but is not obliged) to have a “primary user” in relation to your the use of the Services who shall be responsible for payment, nominating other users and other aspects of your access to the services (primary user). Where that is the case, you anyone who is not the primary userare is responsible under these Terms as if you are the primary user, except in relation to the payment of fees or other rights the primary user may have specifically.",
            indent: 2,
            label: "(i)",
        },
        { text: "Accepting the Terms", indent: 1, label: "2." },
        {
            text: "Permission to use this Services is conditional upon you as licensee agreeing to the Terms. The Services are only offered to you on condition that you read and accept all the Terms and, where applicable, wish to become a licensee of software comprising the Services. Acceptance will bind you and all of your employees to the terms of the licence.",
            indent: 2,
            label: "(a)",
        },
        {
            text: "In order to use the Services, you must first agree to the Terms. You are not allowed to use the Services if you do not accept the Terms.",
            indent: 2,
            label: "(b)",
        },
        { text: "You can accept the Terms by:", indent: 2, label: "(c)" },
        {
            text: `clicking to accept or agree to the Terms (the words "I ACCEPT"), where this option is made available to you by AIM in the user interface for any Service (whether through a web browser, mobile app or otherwise); or`,
            indent: 3,
            label: "(i)",
        },
        {
            text: "by actually using the Services. In this case, you agree that AIM will treat your use of the Services as acceptance of the Terms from that point onwards.",
            indent: 3,
            label: "(ii)",
        },
        {
            text: "By accepting the Terms, you are granted a non-exclusive licence to use the Services subject in entirety to the Terms.",
            indent: 2,
            label: "(d)",
        },
        {
            text: "You may not use the Services if you are not of legal age or capacity to form a binding contract with AIM, or you are a person barred from receiving the Services by law.",
            indent: 2,
            label: "(e)",
        },

        {
            text: "Provision of the Services by AIM",

            indent: 1,
            label: "3.",
        },
        {
            text: "AIM will use all reasonable endeavours to provide the Services as represented on the AIM website, any Services (including app) interface, or on promotional material. The Services may change, but the Terms as published on this website will remain in force. ",
            indent: 2,
            label: "(a)",
        },
        {
            text: "AIM may refuse to allow you to access the Services at its sole discretion, and/or may stop (permanently or temporarily) providing the Services (or any features within the Services) to you or to users generally at AIM’s sole discretion, without prior notice to you. You may stop using the Services at any time. You do not need to specifically inform AIM when you stop using the Services. AIM is not required to act in good faith or reasonably.   ",
            indent: 2,
            label: "(b)",
        },
        {
            text: "If AIM disables access to the website or any Services, or if there is a scheduled or unscheduled outage, you may be prevented from accessing the Services, your account details or any files or other content which is contained therein.",
            indent: 2,
            label: "(c)",
        },

        {
            text: "AIM may in its sole and absolute discretion and without any liability to you or variation of fees payable by you, modify the Services or any component of the Services at any time.",
            indent: 2,
            label: "(d)",
        },

        {
            text: "In the event that AIM publishes or promotes a feature roadmap or otherwise promotes any features of the Services, it shall not be obliged to in fact implement or continue the use of those features.",
            indent: 2,
            label: "(e)",
        },

        { text: "Fees", indent: 1, label: "4." },

        {
            text: "You agree to pay all applicable fees for Services as a condition of the ongoing grant of any licence by AIM to you and your employees.",
            indent: 2,
            label: "(a)",
        },

        {
            text: `(b)	You agree to pay all fees charged based on AIM's pricing, charges, and billing terms notified to you, including prior to acceptance of these Terms. For the avoidance of doubt, this may include any charges contained within the Services. You acknowledge that AIM will offer different tiers of pricing and may change its price offerings from time to time`,

            indent: 2,
            label: "(b)",
        },

        {
            text: "AIM may increase all prices and licence fees associated with the Services by giving you 30 days' written notice. By continuing to use the Services after that time, you will agree to those price changes and the Terms will otherwise continue to apply.",
            indent: 2,
            label: "(c)",
        },

        {
            text: "In the event that you do not pay all required fees for Services as and when required, AIM may terminate the licence granted to use the Services and cease providing the Services to you immediately and without notice.",
            indent: 2,
            label: "(d)",
        },

        {
            text: "Where you pay for the Services based on the number of users (and are the primary account holder), you agree that you may remove a nominated user at any time but will be required to pay all fees for Services attributable to each user for a minimum period of 30 days after they have commenced being a user and have accepted these Terms. You agree that any users removed from access are considered active users for billing purposes until the end of the relevant billing period.",
            indent: 2,
            label: "(e)",
        },

        {
            text: "Your Use of the Services",
            indent: 1,
            label: "5.",
        },

        {
            text: "You (and any user of the Services) will be subject to and responsible under the Terms, and will also be responsible and indemnify AIM against all loss and damage occasioned by AIM as a result of the use of the Services or any breach of the Terms by you and/or any of your employees, servants, agents or contractors.",
            indent: 2,
            label: "(a)",
        },

        {
            text: "In order to access certain Services, you will be required to provide information about yourself (identification, payment and contact details) as part of the registration process for the Service, or as part of your continued use of the Services. You agree that any registration information you give to AIM will always be accurate, correct and up to date, and will be provided in accordance with the AIM Privacy Policy.",
            indent: 2,
            label: "(b)",
        },

        {
            text: "AIM will rely on the accuracy of the information you provide. All Services will be provided in accordance with the information you have provided as at the date of provision of the Services. AIM will not be liable to you in any respect or for any amount for any loss suffered by you) if you have not provided accurate information.",
            indent: 2,
            label: "(c)",
        },

        {
            text: "Any data you provide to AIM will be error free, legible (or audible) in a manner that will reasonably be considered to allow us to provide the Services. We will not be liable to you in any respect for any amount for any loss suffered by you if you have not provided information of such quality.",
            indent: 2,
            label: "(d)",
        },

        {
            text: "You warrant that you have permission to upload or enter any content to the Services that is or may be required.",
            indent: 2,
            label: "(e)",
        },

        {
            text: "You agree to use the Services only for purposes that are agreed at the time of using the Services and in accordance with law.",
            indent: 2,
            label: "(f)",
        },

        {
            text: "You agree not to access (or attempt to access) any of the Services by any means other than through the interface that is provided by AIM, unless you have been specifically allowed to do so in a separate agreement with AIM.",
            indent: 2,
            label: "(g)",
        },

        {
            text: "You agree that you will not do anything that interferes with or disrupts the Services.",
            indent: 2,
            label: "(h)",
        },

        {
            text: "You will be solely responsible for and AIM will not be liable to you or to any third party for) any breach of your obligations under the Terms and for the consequences (including any loss or damage which AIM may suffer) of any such breach. You agree to indemnify AIM for any loss suffered by reason of any breach of your obligations under the Terms (including any consequential damage).",
            indent: 2,
            label: "(i)",
        },

        {
            text: "You agree that you will not reproduce, duplicate, copy, sell, trade or resell the Services for any purpose, and you will not alter, modify, reverse engineer, remove or otherwise obscure any content that you access by using the Services. You must keep all aspects of the Services, including any source code, completely confidential and will not provide any such information to any other party. You will indemnify and hold harmless AIM for any loss it suffers if you breach this obligation.",
            indent: 2,
            label: "(j)",
        },

        {
            text: "Use of the OpenAI GPT application programming interface",
            indent: 1,
            label: "6.",
        },

        {
            text: "AIM may, in the course of delivering the Services, provide access to the OpenAI (or other) generative platform through an application programming interface (API) (AI Services).",
            indent: 2,
            label: "(a)",
        },

        {
            text: "You acknowledge that there is no guarantee that the AI Services will be available at all times, and that AIM's ability to provide the AI Services is subject to it having access to the relevant API",
            indent: 2,
            label: "(b)",
        },

        {
            text: "You acknowledge that the AI Services will attract Fees and agree to pay those Fees as required by these terms as a condition of your use of the Services (and the AI Services).",
            indent: 2,
            label: "(c)",
        },

        {
            text: "The AI Services may be subject to usage restrictions that are applicable to any subscription terms notified to you, including a limit on input and output using the AI Services. You acknowledge that if you exceed those restrictions, you will no longer be able to use the AI Services until your subscription is renewed or, where available, increased",
            indent: 2,
            label: "(d)",
        },

        {
            text: "The AI Services may also be subject to usage frequency limits which are applicable to all users of the AI Services. AIM reserves the right to limit the number of requests that can be made using the AI Services without recourse to you and without notice where it is required to do so in order to adhere to usage frequency limits applicable to the use of the AI Services",
            indent: 2,
            label: "(e)",
        },

        {
            text: "You acknowledge that AIM has no control over the output generated using the AI Services, which is dependent not only on a third party platform but also the content of inputs by users of the AI Services. AIM expressly disclaims liability for all such material. You unconditionally and irrevocably agree to release and indemnify AIM against any and all claims for loss and damage arising out of or in connection with any output or content generated using the AI Services.",
            indent: 2,
            label: "(f)",
        },

        { text: "Your account security and data security", indent: 1, label: "7." },

        {
            text: "You agree and understand that you are responsible for maintaining the confidentiality of passwords or login credentials associated with any account you use to access the Services. AIM will not be responsible for any loss suffered by you or any other person by reason of unauthorised use of your account.",
            indent: 2,
            label: "(a)",
        },

        {
            text: "You may not transfer your account to any other person, unless specifically agreed in writing by AIM.",
            indent: 2,
            label: "(b)",
        },

        {
            text: "If you become aware of any unauthorised use of your password or of your account, you agree to change your account password and notify AIM immediately using the contact details on our website or within the Services.",
            indent: 2,
            label: "(c)",
        },

        {
            text: "TO THE FULLEST EXTENT PERMITTED BY LAW, YOU UNCONDITIONALLY AND IRREVOCABLY RELEASE AIM FROM ALL CLAIMS FOR LOSS AND DAMAGE IN CONNECTION WITH ANY DATA BREACH, HACK OR OTHER INCIDENT WHICH RESULTS IN YOUR DATA BEING ACCESSED BY ANY OTHER PARTY. THIS WILL INCLUDE ANY DATA RELATED TO CREDIT CARD INFORMATION PROVIDED BY YOU.",
            indent: 2,
            label: "(d)",
        },

        { text: "Privacy and personal information", indent: 1, label: "8." },

        {
            text: "AIM will collect personal information about you in order to provide the Services and for purposes otherwise set out in our Privacy Policy at [website]",
            indent: 2,
            label: "(a)",
        },

        { text: "We agree that:", indent: 2, label: "(b)" },

        {
            text: "Your data belongs to you, is confidential, and we have no right to ownership of it;",
            indent: 3,
            label: "(i)",
        },

        {
            text: "We will not copy, look at or access your data except where required by law, which shall be held subject to our Privacy Policy.",
            indent: 3,
            label: "(ii)",
        },

        {
            text: "The information you provide will be collected by or on behalf of us and may be disclosed to third parties that help us deliver our services (including information technology suppliers, communication suppliers and our business partners) or as required by law. If you do not provide this information, we may not be able to provide all our Services. This is limited by our Privacy Policy.",
            indent: 2,
            label: "(c)",
        },

        {
            text: `You agree to the use of your data in accordance with AIM's Privacy Policy. By providing your personal information to us, you consent to the collection, use, storage and disclosure of that information as described in the Privacy Policy and these Terms.`,
            indent: 2,
            label: "(d)",
        },

        { text: "Intellectual property", indent: 1, label: "9." },

        {
            text: "AIM has the right to use the “Architecture in Motion” name, and all intellectual property in the provision of the Services. ",
            indent: 2,
            label: "(a)",
        },

        {
            text: `You acknowledge and agree that AIM (or AIM's licensors) own all legal right, title and interest in and to the Services, including any intellectual property rights which subsist in the Services (whether those rights happen to be registered or not, and wherever in the world those rights may exist). You further acknowledge that the Services may contain information which is designated confidential by AIM and that you shall not disclose such information without AIM's prior written consent.`,
            indent: 2,
            label: "(b)",
        },

        {
            text: "You acknowledge that you have no ownership in the intellectual property comprised in the Services. ",
            indent: 2,
            label: "(c)",
        },

        {
            text: `Unless you have agreed otherwise in writing with AIM, nothing in the Terms gives you a right to use any of AIM's trade names, trademarks, service marks, logos, domain names, and other distinctive brand features (whether owned or licensed by AIM), or any other intellectual property rights belonging to or licensed by AIM, including within the Services.`,
            indent: 2,
            label: "(d)",
        },

        {
            text: "In the event that AIM creates any aspect of the Services or any other work at your request, you irrecovably agree that AIM will own all intellectual property in such work and its product. You will transfer or assign all such rights on request by AIM.",
            indent: 2,
            label: "(e)",
        },

        {
            text: "You agree to wholly indemnify AIM for any loss or damage suffered by AIM by reason of any breach by you of this clause.",
            indent: 2,
            label: "(f)",
        },

        { text: "Indemnity and release", indent: 1, label: "10." },

        {
            text: "To the fullest extent permitted by law, you agree to indemnify AIM and hold it harmless against any loss (either direct or indirect) damage or expense whatsoever which AIM may suffer or incur in respect of:",
            indent: 2,
            label: "(a)",
        },

        { text: "Your use of the Services;", indent: 3, label: "(1)" },
        { text: "Any breach by you of the provisions of the Terms;", indent: 3, label: "(2)" },
        {
            text: "Any claim in connection with your use of the Services by any person;",
            indent: 3,
            label: "(3)",
        },
        {
            text: "Any claim by any person in connection with your infringement of any intellectual property rights arising from your use of the Services; and/or",
            indent: 3,
            label: "(4)",
        },
        {
            text: "Any claim by any person against you arising out of or in respect of the exploitation of the intellectual property in the Services by AIM.",
            indent: 3,
            label: "(5)",
        },

        {
            text: "You irrevocably release AIM and waive all claims which you may have in the future against AIM, in respect of any action, claim or remedy whatsoever in any way attributable to the exploitation of the intellectual property in the Services by AIM, or your use of the Services.",
            indent: 2,
            label: "(b)",
        },

        { text: "Terminating the agreement", indent: 1, label: "11." },

        {
            text: "The Terms will continue to apply until terminated by either you or AIM as set out below.",
            indent: 2,
            label: "(a)",
        },

        {
            text: "You may terminate this agreement by giving notice or any other notice requirement specified in Further Terms or other agreement between you and AIM. Any fees paid prior to termination will not be refundable to you.",
            indent: 2,
            label: "(b)",
        },

        {
            text: "AIM may at any time, terminate its legal agreement with you if:",
            indent: 2,
            label: "(c)",
        },

        {
            text: "you have breached any provision of these Terms (or have acted in manner which clearly shows that you do not intend to, or are unable to comply with the provisions of this agreement); or",
            indent: 3,
            label: "(i)",
        },

        { text: "AIM is required to do so by law; or", indent: 3, label: "(ii)" },

        {
            text: "any third party regarding whom AIM offered the Services to you has terminated its relationship with AIM or ceased to offer the Services to AIM or you; or",
            indent: 3,
            label: "(iii)",
        },

        {
            text: "you or your employer have not made payment as required for the Services;",
            indent: 3,
            label: "(iv)",
        },

        {
            text: "AIM is transitioning to no longer providing the Services to users in the country in which you are resident or from which you use the service; or",
            indent: 3,
            label: "(v)",
        },

        {
            text: `the provision of the Services to you by AIM is, in AIM's opinion, no longer commercially viable; or`,
            indent: 3,
            label: "(vi)",
        },

        {
            text: "for any other reason AIM in its reasonable discretion thinks fit.",
            indent: 3,
            label: "(vii)",
        },

        {
            text: "Termination of the agreement will result in an automatic revocation of any licence provided under it in relation to the Services, unless otherwise agreed in writing with AIM. ",
            indent: 2,
            label: "(d)",
        },

        {
            text: "Nothing in this Section shall affect AIM's rights regarding provision of Services under the Terms.",
            indent: 2,
            label: "(e)",
        },

        { text: "EXCLUSION OF LIABILITY", indent: 1, label: "12." },

        {
            text: "You expressly acknowledge that AIM will not back up any data related to the Services. It is your sole responsibility to back up all data, subject to your compliance with the Terms. AIM shall not be responsible under any circumstances for any data loss suffered by you and you unconditionally release AIM from all associated claims.",
            indent: 2,
            label: "(a)",
        },

        {
            text: `NOTHING IN THESE TERMS, SHALL EXCLUDE OR LIMIT AIM's WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW OR OTHERWISE CONTAINED IN ANY WARRANTY IDENTIFIED IN ANY FURTHER TERMS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR CONDITIONS OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR LOSS OR DAMAGE CAUSED BY NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF IMPLIED TERMS, OR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, ONLY THE LIMITATIONS WHICH ARE LAWFUL IN YOUR JURISDICTION WILL APPLY TO YOU AND OUR LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.`,
            indent: 2,
            label: "(b)",
        },

        {
            text: `YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED "AS IS" AND “AS AVAILABLE.” AIM HAS NO SERVICE LEVEL AGREEMENT FOR YOUR BENEFIT, BUT IN THE EVENT OF ANY PERFORMANCE ISSUES, MAY (BUT IS NOT OBLIGED) TO RESPOND AND PROVIDE SUPPORT.`,
            indent: 2,
            label: "(c)",
        },

        {
            text: "AIM WILL USE A THIRD PARTY PLATFORM TO HOST THE SERVICES. ACCORDINGLY, IT WILL HAVE NO CONTROL OVER THE PERFORMANCE OR UPTIME OF THE SERVICES. YOU EXPRESSLY ACKNOWLEDGE THIS AND RELEASE AIM FROM ALL CLAIMS FOR ANY LOSS AND DAMAGE SUFFERED IN CONNECTION WITH ANY DOWNTIME OR FAILURE OF PERFORMANCE OF THE SERVICES.",
            indent: 2,
            label: "(d)",
        },

        {
            text: "IN PARTICULAR, AIM DO NOT REPRESENT OR WARRANT TO YOU THAT (AND YOU WILL HAVE NO CLAIM FOR ANY LIABILITY, LOSS OR DAMAGE ARISING OUT OF THE FOLLOWING):",
            indent: 2,
            label: "(e)",
        },

        { text: "YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS,", indent: 3, label: "(i)" },

        {
            text: "THE ADVICE YOU RECEIVE USING THE SERVICES WILL BE ACCURATE OR FIT FOR PURPOSE;",
            indent: 3,
            label: "(ii)",
        },

        {
            text: "ANY OF THE INFORMATION OR CONTENT YOU ACCESS USING THE SERVICES IS ACCURATE, RELIABLE, OR THAT ANY PARTICULAR RESULT OR EFFECT WILL BE ACHIEVED BY USING THE SERVICES OR ANY OF THE CONTENT ACCESSED OR DOWNLOADED USING THE SERVICES,",
            indent: 3,
            label: "(iii)",
        },

        {
            text: "YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, BUGS OR VIRUSES,",
            indent: 3,
            label: "(iv)",
        },

        {
            text: "ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, AND",
            indent: 3,
            label: "(v)",
        },

        {
            text: "THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.",
            indent: 3,
            label: "(vi)",
        },

        {
            text: "ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.",
            indent: 2,
            label: "(f)",
        },

        {
            text: "NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM AIM OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.",
            indent: 2,
            label: "(g)",
        },

        {
            text: "AIM FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, TO THE FULL EXTENT PERMITTED BY LAW.",
            indent: 2,
            label: "(h)",
        },

        { text: "LIMITATION OF LIABILITY", indent: 1, label: "13." },

        {
            text: "YOU EXPRESSLY UNDERSTAND AND AGREE THAT AIM, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS SHALL NOT BE LIABLE TO YOU FOR:",
            indent: 2,
            label: "(a)",
        },

        {
            text: "ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS;",
            indent: 3,
            label: "(i)",
        },

        {
            text: "ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU., INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICES.",
            indent: 3,
            label: "(ii)",
        },

        {
            text: "ANY CHANGES WHICH AIM MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);",
            indent: 3,
            label: "(iii)",
        },

        {
            text: "THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES;",
            indent: 3,
            label: "(iv)",
        },

        {
            text: "YOUR FAILURE TO PROVIDE AIM WITH ACCURATE ACCOUNT INFORMATION;",
            indent: 3,
            label: "(v)",
        },

        {
            text: "YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL;",
            indent: 3,
            label: "(vi)",
        },

        {
            text: `THE LIMITATIONS ON AIM'S LIABILITY TO YOU ABOVE SHALL APPLY WHETHER OR NOT AIM HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.`,
            indent: 2,
            label: "(b)",
        },

        {
            text: "WE SHALL NOT BE LIABLE TO YOU FOR ANY DELAY OR FAILURE TO PERFORM OR PROVIDE THE SERVICES BY REASON OF ANY CIRCUMSTANCES BEYOND OUR REASONABLE CONTROL.",
            indent: 2,
            label: "(c)",
        },

        {
            text: "TO THE EXTENT PERMITTED BY LAW, IN THE EVENT THAT AIM IS FOUND TO BE LIABLE TO YOU, OUR LIABILITY SHALL BE LIMITED TO A REFUND OF AMOUNTS PAID BY YOU TO AIM, A REPLACEMENT OF THE SERVICES SUPPLIED, OR REPERFORMANCE OF THE SERVICES SUPPLIED.",
            indent: 2,
            label: "(d)",
        },

        { text: "Other content", indent: 1, label: "14." },

        {
            text: "The Services may include hyperlinks to other web sites or content or resources. AIM may have no control over any web sites or resources which are provided by companies or persons other than AIM.",
            indent: 2,
            label: "(a)",
        },

        {
            text: "You acknowledge and agree that AIM is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources, and is not liable for any loss or damage which may be incurred by you in accessing those websites or resources.",
            indent: 2,
            label: "(b)",
        },

        { text: "Changes to the Terms", indent: 1, label: "15." },

        {
            text: "AIM may make changes to the Terms from time to time. When these changes are made, AIM will make a new copy of the Terms available on any platform through which the Services are accessed. AIM shall not be required to otherwise notify you of any changes to the Terms, and you agree to be bound by the Terms as applicable from time to time. AIM may choose to notify a primary account holder.",
            indent: 2,
            label: "(a)",
        },

        {
            text: "You understand and agree that if you use the Services after the date on which the Main Terms or Further Terms have changed, AIM will treat your use as acceptance of the updated Terms.",
            indent: 2,
            label: "(b)",
        },

        { text: "General", indent: 1, label: "16." },

        {
            text: "Sometimes when you use the Services, you may (as a result of, or through your use of the Services) use a service or download a piece of software, or purchase goods, which are provided by another person or company. Your use of these other services, software or goods may be subject to separate terms between you and the company or person concerned. If so, the Terms do not affect your legal relationship with these other companies or individuals.",
            indent: 2,
            label: "(a)",
        },

        {
            text: "The Terms constitute the whole legal agreement between you and AIM and govern your use of the Services (but excluding any services which AIM may provide to you under a separate written agreement), and completely replace any prior agreements and/or representations between you and AIM in relation to the Services.",
            indent: 2,
            label: "(b)",
        },

        {
            text: `You may not assign your obligations or rights under the Terms without AIM's written consent. AIM may assign its rights and obligations under the Terms at any time and without the need for your consent.`,
            indent: 2,
            label: "(c)",
        },

        {
            text: "You agree that AIM may provide you with notices, including those regarding changes to the Terms, by email, regular mail, or postings on the Services.",
            indent: 2,
            label: "(d)",
        },

        {
            text: `You agree that if AIM does not exercise or enforce any legal right or remedy which is contained in the Terms (or which AIM has the benefit of under any applicable law), this will not be taken to be a waiver of AIM's rights and that those rights or remedies will still be available to AIM.`,
            indent: 2,
            label: "(e)",
        },

        {
            text: "If any court of competent jurisdiction rules that any provision of these Terms is invalid, then that provision will be read down or removed from the Terms without affecting the rest of the Terms. The remaining provisions of the Terms will continue to be valid and enforceable.",
            indent: 2,
            label: "(f)",
        },

        {
            text: "No agency or partnership shall be construed to have been created by virtue of this agreement.",
            indent: 2,
            label: "(g)",
        },

        {
            text: "You acknowledge and agree that each member of any company or entity to which AIM is directly related (through whole or part ownership or control) shall be third party beneficiaries to the Terms and that such other companies shall be entitled to directly enforce, and rely upon, any provision of the Terms which confers a benefit on (or rights in favour of) them. Other than this, no other person or company shall be third party beneficiaries to the Terms.",
            indent: 2,
            label: "(h)",
        },

        {
            text: "The Terms, and your relationship with AIM under the Terms, shall be governed by the laws of the State of New South Wales (or Australia where applicable). You and AIM agree to submit to the non-exclusive jurisdiction of the courts located within the State of New South Wales (or Australia, where applicable) to resolve any legal matter arising from the Terms. ",
            indent: 2,
            label: "(i)",
        },

        {
            text: "You acknowledge and warrant that you are of legal capacity and have obtained, or had a reasonable opportunity to obtain independent legal advice in relation to the Terms and enter this agreement with an understanding of the legal and practical effect of the Terms. AIM shall not be liable for any breach of this warranty.",
            indent: 2,
            label: "(j)",
        },
    ]

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancel}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    Accept End User License Agreement (EULA)
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"} onScroll={(e) => handleScroll(e)}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {eula.map((item, index) => (
                            <Box
                                sx={{ display: "flex", flexDirection: "row", marginTop: "5px" }}
                                key={index}
                            >
                                <Box
                                    sx={{
                                        marginLeft: `${(item.indent - 1) * 10}px`,
                                        marginRight: "10px",
                                        fontWeight: item.indent === 1 && "bold",
                                    }}
                                    key={`${index}-label`}
                                >
                                    {item.label}
                                </Box>
                                <Box
                                    key={`${index}-text`}
                                    sx={{ fontWeight: item.indent === 1 && "bold" }}
                                >
                                    {item.text}
                                </Box>
                            </Box>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>CANCEL</Button>
                    <Button onClick={handleAccept} disabled={!scrolledToEnd}>
                        I ACCEPT
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AcceptEULADialog
