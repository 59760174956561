import {
  FormControlLabel,
  Typography,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { useState } from "react"

const useSelectShader = () => {
  const [selectedShaderId, setSelectedShaderId] = useState("")

  const SelectShader = ({ shaderOptions, applyShaders }) => {
    return (
      <Box sx={{ mr: 4, mt: 1 }}>
        <FormControlLabel
          labelPlacement="start"
          label={
            <Typography sx={{ mr: "5px" }} variant="caption">
              Shader
            </Typography>
          }
          control={
            <Select
              id="elementShader"
              value={selectedShaderId}
              onChange={(e) => setSelectedShaderId(e.target.value)}
              renderValue={(value) => {
                return (
                  shaderOptions.find((option) => option.id === value)?.title ||
                  "None"
                )
              }}
              disabled={!applyShaders}
              label="Element Shader"
              size="small"
              autoWidth={true}
              variant="standard"
              sx={{ width: "150px" }}
            >
              <MenuItem value="">
                <ListItemIcon />
                <ListItemText>None</ListItemText>
              </MenuItem>
              {shaderOptions &&
                shaderOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                      }}
                    >
                      <ListItemIcon>
                        {option.usedInCurrentView && <VisibilityIcon />}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: !option.used && "#BBB",
                          fontStyle: !option.used && "italic",
                        }}
                      >
                        {option.title}
                      </ListItemText>
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          }
        />
      </Box>
    )
  }

  return { SelectShader, selectedShaderId }
}

export default useSelectShader
