import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  Select,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import db from "../Firestore"
import { useState } from "react"
import { aiIcon } from "../pages/services/colorServices"
import * as palette from "../components/symbols/palette"

const PropSpec = ({
  prop,
  setProp,
  handleDeleteProp,
  accountId,
  handleGetSuggestedTags,
  setElementType,
  suggestedTags,
  files,
  selectedFile,
  setSelectedFile,
}) => {
  const [existingShader, setExistingShader] = useState()

  const [isGettingTags, setIsGettingTags] = useState(false)

  const handleFindExistingProp = (e) => {
    const name = e.target.value

    // Check if name already as a shader name

    console.log("Check if name already as a shader name", { name, accountId })

    if (name !== "") {
      db.collection("shaders")
        .where("account_id", "==", accountId)
        .where("property", "==", name.toLowerCase())
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            console.log(
              "found existing shader(s)",
              querySnapshot.docs.map((doc) => doc.data().name)
            )
            setExistingShader(querySnapshot.docs[0].data())
          } else {
            setExistingShader(undefined)
          }
        })
    }
  }

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Box>
            <Typography
              variant="subtitle1"
              gutterBottom={true}
              sx={{ mb: 2, mt: 1 }}
            >
              Enter the name of the tag you wish to apply to your diagram. Or
              click Suggest to have AI suggest the best tags to add.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignContent: "center",
              }}
            >
              <Controls.TextInput
                label="Tag Name"
                value={prop.name}
                onChange={(e) => setProp({ ...prop, name: e.target.value })}
                onBlur={handleFindExistingProp}
                autoComplete="off"
                helperText="Criticality, Importance, Complexity, etc."
              />
              <Stack
                direction="row"
                sx={{ alignItems: "center", mb: 2 }}
                gap={2}
              >
                <Tooltip title="Automatically suggest the best properties to add">
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    disabled={isGettingTags}
                    onClick={() => {
                      setIsGettingTags(true)
                      handleGetSuggestedTags().then((result) => {
                        setIsGettingTags(false)
                      })
                    }}
                    endIcon={<AutoAwesomeIcon sx={aiIcon} />}
                  >
                    Suggest
                  </Button>
                </Tooltip>
                {isGettingTags && (
                  <CircularProgress size={20} sx={{ color: aiIcon }} />
                )}
              </Stack>
            </Box>
          </Box>

          {handleDeleteProp && (
            <Box>
              <Tooltip title="Delete Property">
                <IconButton onClick={() => handleDeleteProp(prop.seq)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Stack>
        {existingShader && (
          <Box sx={{ pb: "15px" }}>
            <Alert severity="info" sx={{ mt: "5px", mb: "10px", p: "5px" }}>
              There is already a shader called <b>{existingShader.name}</b>. It
              has the following values:
              <b>
                {existingShader.config.colors.map((c) => c.value).join(", ")}
              </b>
              . Only add this property if it aligns with the existing property
              meaning.
            </Alert>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Controls.Button
                label="Use these values"
                onClick={() => {
                  setProp({
                    ...prop,
                    description: `Use the following values: ${existingShader.config.colors
                      .map((c) => c.value)
                      .join(", ")}`,
                  })
                }}
              />
            </Box>
          </Box>
        )}
        <Box>
          <Typography
            variant="subtitle1"
            gutterBottom={true}
            sx={{ mb: 2, mt: 1 }}
          >
            Enter the prompt used to apply tags to your diagram. Click Suggest
            Tag Names for ideas.
          </Typography>
          <Controls.TextInput
            label="Prompt"
            value={prop.description}
            onChange={(e) => setProp({ ...prop, description: e.target.value })}
            multiline={true}
            helperText="Prompt to generate tag values"
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            gutterBottom={true}
            sx={{ mb: 2, mt: 1 }}
          >
            (Optional) Select files to use in AI generation of tag values. Files
            are uploaded from the AI Designer Dashboard under 'Documents'.
          </Typography>
          <FormControl>
            <InputLabel id="file-select">File</InputLabel>
            <Select
              labelId="file-select"
              id="file-select-id"
              label="File"
              onChange={(e) => {
                setSelectedFile(e.target.value)
              }}
              variant="outlined"
              size="small"
              sx={{ width: "300px" }}
              value={selectedFile}
            >
              <MenuItem value={""}>None</MenuItem>
              {files.map((file) => {
                return (
                  <MenuItem key={file.name} value={file.name}>
                    {file.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>

        {suggestedTags.length > 0 && (
          <Box>
            <Typography variant="h6" gutterBottom={true} sx={{ mt: 1 }}>
              Suggested Tags
            </Typography>
            <Typography variant="subtitle1" gutterBottom={true} sx={{ mt: 1 }}>
              These are the tags that AI suggests you add to your diagram. Click
              a tag to select, edit the prompt as required, and then press Generate.
            </Typography>
            <List>
              {suggestedTags.map((tag) => (
                <ListItemButton
                  key={tag.name}
                  onClick={() => {
                    setProp({
                      ...prop,
                      name: tag.name,
                      description: `${tag.prompt}. Provide a relative assessment where each value is applied at least once.`,
                    })
                    const elType = palette.getElementType(tag.element_type)
                    console.log("setElementType", {
                      tagElType: tag.element_type,
                      elType,
                    })
                    setElementType(elType.index)
                  }}
                >
                  <ListItemText
                    primary={
                      <Stack direction="row" gap={1}>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={"bold"}
                        >
                          {tag.name}
                        </Typography>
                        <Typography variant="caption" color="text.primary">
                          ({palette.formatLabel(tag.element_type)})
                        </Typography>
                      </Stack>
                    }
                    secondary={
                      <Typography variant="caption" color="text.secondary">
                        {tag.reason}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default PropSpec
