import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { useEffect, useMemo } from "react"
import { useState } from "react"
import Controls from "./controls/Controls"
import Select from "./controls/Select"
import DraggablePaper from "../components/DraggablePaper"

const ModelEditRenamePropertyDialog = (props) => {
  const { open, setOpen, uniquePropKeys, handleRename } = props

  const [fromName, setFromName] = useState("")

  const [toName, setToName] = useState("")

  useEffect(() => {
    setFromName("")
    setToName("")
  }, [])

  const existingNames = useMemo(() => {
    if (!uniquePropKeys) return []
    return uniquePropKeys.filter(
      (item) => item.toLowerCase() === toName.toLowerCase()
    )
  }, [toName, uniquePropKeys])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperComponent={DraggablePaper}
    >
      <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
        Rename Property
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            minWidth: "250px",
          }}
        >
          <Select
            label="Rename from"
            options={uniquePropKeys.map((item) => ({ id: item, title: item }))}
            addNoneOption={false}
            value={fromName}
            onChange={(e) => setFromName(e.target.value)}
          />
          <Controls.TextInput
            label="Rename to"
            value={toName}
            onChange={(e) => setToName(e.target.value)}
            fullWidth={false}
          />
          {existingNames.length > 0 && (
            <Alert severity="info">
              <b>{toName}</b> is an existing property.
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setOpen(false)
            handleRename(fromName, toName)
          }}
          sx={{ textTransform: "none" }}
          variant="contained"
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModelEditRenamePropertyDialog
