import {
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Tooltip,
  colors,
  Card,
  Box,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import PushPinIcon from "@mui/icons-material/PushPin"
import DeleteIcon from "@mui/icons-material/Delete"
import StarIcon from "@mui/icons-material/Star"
import PreviewIcon from "@mui/icons-material/Preview"
import * as palette from "./symbols/palette"
import { aiIcon } from "../pages/services/colorServices"

const ModelEditQuestion = ({
  viewSet,
  views,
  question,
  isGenerating,
  handleCreateSuggestedView,
  handleTogglePinQuestion,
  handleShowView,
  handleDeleteQuestion,
  disableCreateSuggestedView,
  selectedQuestionId,
  setSelectedQuestionId,
  cardProps = { maxWidth: "320px", position: "relative" },
}) => {
  const layer = palette.LAYERS.find((l) => question.layer?.startsWith(l.name))

  const [isBusy, setBusy] = useState(false)

  const [questionForView, setQuestionForView] = useState()

  // show pin icon if question is already pinned, or mouse is over the card
  const [isShowPinIcon, setShowPinIcon] = useState(
    question.pinned && !isGenerating
  )

  const isDependency = useMemo(() => {
    return question.prerequisites?.find(
      (d) => d.prerequisite_question_id === selectedQuestionId
    )
  }, [selectedQuestionId, question])

  // Has a suggested view already been created based on this question
  // where the question.question matches the suggested view 'question' attribute
  const isViewSuggestionFound = useMemo(() => {
    if (viewSet.view_suggestions && question.question_id) {
      const viewSuggestion = viewSet.view_suggestions.find(
        (sv) => sv.question_id === question?.question_id
      )

      // if (viewSuggestion) {
      //   console.log(
      //     "%cfound question",
      //     "color:lightGreen",
      //     question.question_id,
      //     question.question,
      //     viewSuggestion
      //   )
      //}
      return Boolean(viewSuggestion)
    }

    return false
  }, [viewSet, question])

  /**
   * @return true if a view has been created with the given question
   */
  const isViewFound = useMemo(() => {
    return (
      views.find(
        (v) =>
          v.question_id &&
          question.question_id &&
          v?.question_id === question.question_id
      ) !== undefined
    )
  }, [views, question])

  useEffect(() => {
    setShowPinIcon(isBusy || isGenerating ? false : question?.pinned)
  }, [question, isBusy, isGenerating])

  const [isPinHovering, setPinHovering] = useState(false)

  const pinIconStyle = useMemo(() => {
    if (question?.pinned) {
      return { color: colors.grey[600] }
    }

    if (isPinHovering) {
      return { color: colors.grey[200] }
    }

    return {}
  }, [question, isPinHovering])

  useEffect(() => {
    if (questionForView && isBusy) {
      setTimeout(() => {
        handleCreateSuggestedView(questionForView).then(() => {
          setBusy(false)
        })
      }, 500)
    }
  }, [questionForView, isBusy])

  const titleStyle = useMemo(() => {
    return isViewFound ? { fontWeight: "bold", color: colors.grey[800] } : {}
  }, [isViewFound])

  const cardHeaderStyle = useMemo(() => {
    return isDependency
      ? { backgroundColor: colors.blue[200] }
      : isViewSuggestionFound
      ? { backgroundColor: layer?.color || colors.grey[200] }
      : {}
  }, [layer, isDependency, isViewSuggestionFound])

  return (
    <Card
      sx={cardProps}
      onMouseEnter={() => {
        setPinHovering(true)
        if (!isBusy && !isGenerating) {
          setShowPinIcon(true)
        }
        if (setSelectedQuestionId) {
          setSelectedQuestionId(question.question_id)
        }
      }}
      onMouseLeave={() => {
        setPinHovering(false)
        if (!isBusy && !isGenerating) {
          setShowPinIcon(question.pinned)
        }
        if (setSelectedQuestionId) {
          setSelectedQuestionId(undefined)
        }
      }}
    >
      <CardHeader
        sx={cardHeaderStyle}
        title={
          <Typography variant="body1" sx={titleStyle}>
            {question.question}
          </Typography>
        }
        avatar={
          <Tooltip title={layer?.name}>
            <Avatar
              sx={{
                bgcolor: layer?.colorDark,
              }}
              aria-label="question"
            >
              {layer?.name[0] || "X"}
            </Avatar>
          </Tooltip>
        }
      />
      <CardContent sx={{ mb: "30px" }}>
        <Stack direction="column" gap={1}>
          <Typography variant="body2" color="text.secondary">
            {question.rationale}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {question.sort_rationale}
          </Typography>

          {question.element_types && question.element_types.length > 0 && (
            <Box>
              <Typography variant="body2" color="text.primary">
                Use:{" "}
                {question.element_types
                  ?.map((el) => palette.formatLabel(el))
                  .join(", ")}
              </Typography>
            </Box>
          )}
          {question.next_best_reason && (
            <Stack
              direction="row"
              gap={1}
              sx={{
                backgroundColor: colors.pink[50],
                p: "10px",
                borderRadius: "10px",
                mb: "10px",
              }}
            >
              <StarIcon sx={{ color: colors.yellow[700] }} />
              <Typography variant="body2">
                {question.next_best_reason}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "flex-end",
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "100%",
        }}
      >
        {isBusy && (
          <Stack direction="row" gap={1}>
            <Typography variant="caption">Creating view recipe...</Typography>
            <CircularProgress size={20} />
          </Stack>
        )}

        {handleTogglePinQuestion && (
          <Tooltip title="Keep this question when you regenerate new questions">
            <span>
              <IconButton
                onClick={() => {
                  handleTogglePinQuestion(question)
                }}
              >
                {isShowPinIcon && <PushPinIcon sx={pinIconStyle} />}
              </IconButton>
            </span>
          </Tooltip>
        )}

        {isViewFound && (
          <Tooltip title="Show view created using this question">
            <IconButton onClick={() => handleShowView(question.question_id)}>
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        )}

        {handleDeleteQuestion && (
          <Tooltip title="Delete question">
            <span>
              <IconButton onClick={handleDeleteQuestion}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}

        <Tooltip
          title={
            isViewSuggestionFound
              ? "Already exists. Check the View Recipes tab"
              : "Create View Recipe"
          }
        >
          <span>
            <IconButton
              disabled={
                disableCreateSuggestedView ||
                isViewSuggestionFound ||
                Boolean(isGenerating)
              }
              onClick={() => {
                setBusy(true)
                setQuestionForView(question)
              }}
            >
              {!isBusy && <AddIcon sx={isViewSuggestionFound ? {} : aiIcon} />}
            </IconButton>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

export default ModelEditQuestion
