import React from "react"
import {
  Typography,
  Box,
  Button,
  ButtonGroup,
  Paper,
  Tab,
  styled,
  Divider,
} from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import ImageIcon from "@mui/icons-material/Image"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import ChatIcon from "@mui/icons-material/Chat"
import ReferenceIcon from "@mui/icons-material/Link"
import { Delete } from "@mui/icons-material"

function Ribbon({
  DisplayAsImage,
  handleSetShowDiagramAsImage,
  currentView,
  RenameView,
  AddView,
  EditObjective,
  DashboardButton,
  SaveButton,
  SelectShader,
  ShadeElements,
  HideUnshaded,
  ShowLegend,
  ShowUsage,
  RenameProperty,
  ShadeProperties,
  ShowProperties,
  PropertyTypes,
  QuicksetProperties,
  ShowElementTypes,
  LayoutOptions,
  DescriptionSelector,
  CreateContent,
  AddTags,
  AddDescription,
  CreateViewReference,
  CreatePrompt,
  Download,
  Import,
  MoveView,
  PasteJSON,
  AddSibling,
  AddChild,
  DeleteElement,
  EditElement,
  MoveElementLeft,
  MoveElementRight,
  SelectView,
  CreateNewView,
}) {
  const [activeTab, setActiveTab] = React.useState("0")

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const quickAccessGroupProps = {
    DisplayAsImage,
    handleSetShowDiagramAsImage,
    currentView,
    DashboardButton,
    SaveButton,
  }

  return (
    <TabContext value={activeTab}>
      {/* Ribbon Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Ribbon Tabs"
        >
          <Tab label="Home" value="0" />
          <Tab label="Edit" value="1" />
          <Tab label="Data" value="2" />
          <Tab label="AI" value="3" />
          <Tab label="Format" value="4" />
          <Tab label="Layout" value="5" />
          <Tab label="Properties" value="6" />
        </TabList>
      </Box>

      {/* Home Tab Content */}
      <RibbonMenu value="0">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {/* Quick Access Group */}
          <QuickAccessGroup {...quickAccessGroupProps} />

          {/* View Management Group */}
          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              View Management
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="view management buttons"
            >
              <AddView />
              <RenameView />
              <EditObjective />
              <SelectView />
            </ButtonGroup>
          </IconPanel>
        </Box>
      </RibbonMenu>

      {/* Edit Tab Content */}
      <RibbonMenu value="1">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <QuickAccessGroup {...quickAccessGroupProps} />

          {/* Elements Group */}
          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              Elements
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="elements buttons"
            >
              <PasteJSON />
              <AddSibling />
              <AddChild />
              <CreateNewView />
              <DeleteElement />
              <EditElement />
              <MoveElementLeft />
              <MoveElementRight />
            </ButtonGroup>
          </IconPanel>
        </Box>
      </RibbonMenu>

      {/* Data & AI Tab Content */}
      <RibbonMenu value="2">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <QuickAccessGroup {...quickAccessGroupProps} />

          {/* Data Operations Group */}

          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              Data Operations
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="data operations buttons"
            >
              <Download />
              <Import />
              <MoveView />
            </ButtonGroup>
          </IconPanel>
        </Box>
      </RibbonMenu>

      <RibbonMenu value="3">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <QuickAccessGroup {...quickAccessGroupProps} />

          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              AI Tools
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="ai tools buttons"
            >
              <CreateContent />
              <AddTags />
              <AddDescription />
              <CreateViewReference />
              <CreatePrompt />
            </ButtonGroup>
          </IconPanel>
        </Box>
      </RibbonMenu>

      {/* View & Format Tab Content */}
      <RibbonMenu value="4">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <QuickAccessGroup {...quickAccessGroupProps} />

          {/* Visualization Group */}

          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              Visualization
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="visualization buttons"
            >
              <SelectShader />
              <ShadeElements />
              <HideUnshaded />
              <ShowLegend />
              <ShowUsage />
            </ButtonGroup>
          </IconPanel>

          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              Show
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="layout options buttons"
            >
              <ShowElementTypes />
              <DescriptionSelector />
            </ButtonGroup>
          </IconPanel>
        </Box>
      </RibbonMenu>

      <RibbonMenu value="5">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <QuickAccessGroup {...quickAccessGroupProps} />

          {/* Visualization Group */}

          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              Layout
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="properties buttons"
            >
              <LayoutOptions />
            </ButtonGroup>
          </IconPanel>
        </Box>
      </RibbonMenu>

      <RibbonMenu value="6">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <QuickAccessGroup {...quickAccessGroupProps} />

          {/* Visualization Group */}

          <IconPanel elevation={0}>
            <Typography variant="subtitle1" gutterBottom>
              Properties
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="secondary"
              aria-label="properties buttons"
            >
              <RenameProperty />
              <ShowProperties />
              <PropertyTypes />
              <QuicksetProperties />
              <ShadeProperties />
            </ButtonGroup>
          </IconPanel>
        </Box>
      </RibbonMenu>
    </TabContext>
  )
}

const IconPanel = styled(Paper)(({ theme }) => ({
  elevation: 0,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

const QuickAccessGroup = ({
  DisplayAsImage,
  DashboardButton,
  SaveButton,
  handleSetShowDiagramAsImage,
  currentView,
}) => {
  return (
    <IconPanel elevation={0}>
      <Typography variant="subtitle1" gutterBottom>
        Quick Access
      </Typography>
      <ButtonGroup
        variant="outlined"
        color="primary"
        aria-label="quick access buttons"
      >
        <SaveButton />
        <DashboardButton />
        <Divider orientation="vertical" flexItem />
        <Box sx={{ ml: 2 }}>
          <DisplayAsImage
            handleSetShowDiagramAsImage={handleSetShowDiagramAsImage}
            currentView={currentView}
          />
        </Box>
      </ButtonGroup>
    </IconPanel>
  )
}

const RibbonMenu = styled(TabPanel)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(4),
  borderBottom: "1px solid #ccc",
}))

export default Ribbon
