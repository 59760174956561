import React, { useState } from "react"
import Controls from "./controls/Controls"
import { Typography, Tooltip, Switch, Box, Alert } from "@mui/material"
import Tags from "./Tags"
import { parseTags } from "../pages/services/tagServices"
import { useEffect } from "react"
import { spacing } from "../pages/services/styleServices"

const styles = {
    editSwitch: {
        marginLeft: spacing(2),
    },
}

const TagPanel = (props) => {
    // showNoTagsAlert = since this component is used in different nested ways, we don't always want to show an alert if the view has no tags
    const {
        tags,
        handleUpdateTags,
        heading,
        readonly = false,
        showNoTagsAlert = false,
        // Optional external flag to control if tags are editable
        isEditing = undefined,
    } = props

    const [addTag, setAddTag] = useState("")

    const [editTags, setEditTags] = useState(false)

    const [showEditSwitch, setShowEditSwitch] = useState(false)

    const handleTagChange = (event) => {
        setAddTag(event.target.value)
    }

    useEffect(() => {
        setShowEditSwitch(isEditing === undefined)
        if (isEditing !== undefined) {
            setEditTags(isEditing)
        }
    }, [isEditing])

    const handleTagKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            const enteredTags = parseTags(addTag)

            const newTags = enteredTags.filter((newTag) => {
                const exists =
                    tags.find((tag) => tag.label === newTag.label && tag.type === newTag.type) !==
                    undefined

                return !exists
            })

            //console.log("newTags", newTags)

            const updatedTags = [...tags, ...newTags]

            //console.log("updatedTags", updatedTags)

            handleUpdateTags(updatedTags)

            setAddTag("")
        }
    }

    const handleTagDelete = (tagToDelete) => {
        const newTags = tags.filter(
            (tag) => !(tag.type === tagToDelete.type && tag.label === tagToDelete.label)
        )
        handleUpdateTags(newTags)
    }

    return (
        <Box>
            <Box>
                <Box style={{ marginTop: "10px" }}>
                    <Box style={{ display: "flex" }}>
                        <Box>
                            <Tooltip title="Add tags to help group and filter this item in the Explorer">
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component={"span"}
                                >
                                    {heading}
                                </Typography>
                            </Tooltip>
                        </Box>
                        {!readonly && showEditSwitch && (
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignSelf: "flex-end",
                                    alignItems: "center",
                                    marginLeft: "auto",
                                }}
                            >
                                <Switch
                                    value={editTags}
                                    color="primary"
                                    sx={styles.editSwitch}
                                    onChange={() => setEditTags(!editTags)}
                                />
                                <Typography variant="caption" component={"span"}>
                                    Edit
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    {showNoTagsAlert && tags && tags.length === 0 && (
                        <Box>
                            <Alert severity="info">No tags defined. Click Edit to add tags</Alert>
                        </Box>
                    )}

                    <Box>
                        {tags && (
                            <Tags
                                tags={tags}
                                handleTagDelete={handleTagDelete}
                                editTags={editTags}
                            />
                        )}
                    </Box>
                </Box>
            </Box>

            {editTags && (
                <Box style={{ marginTop: "15px", maxWidth: "500px" }}>
                    <Controls.TextInput
                        autoFocus
                        name="tag"
                        label="Add search tag"
                        helperText={`Format 'type:label', e.g. 'Domain:Sales', 'Status:Concept'`}
                        value={addTag}
                        onChange={handleTagChange}
                        onKeyPress={(event) => handleTagKeyPress(event)}
                    />
                </Box>
            )}
        </Box>
    )
}

export default TagPanel
