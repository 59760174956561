import { Box, Tooltip, IconButton, colors } from "@mui/material"
import { useState } from "react"
import ImageIcon from "@mui/icons-material/Image"
import SquareIconButton from "../SquareIconButton"

const useDisplayAsImage = () => {
  const [showRender, setShowRender] = useState(false)

  const DisplayAsImage = ({ handleSetShowDiagramAsImage, currentView }) => (
    <Tooltip title="Show selected view as an image">
      <span>
        <SquareIconButton
          onClick={(e) => handleSetShowDiagramAsImage(!showRender)}
          sx={{
            backgroundColor: showRender ? colors.grey[200] : "transparent",
          }}
          disabled={currentView === undefined}
        >
          <ImageIcon />
        </SquareIconButton>
      </span>
    </Tooltip>
  )

  return { DisplayAsImage, showRender, setShowRender }
}

export default useDisplayAsImage
