import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  colors,
} from "@mui/material"
import WrapTextIcon from "@mui/icons-material/WrapText"
import SwapVertIcon from "@mui/icons-material/SwapVert"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import SquareIconButton from "./SquareIconButton"

const ModelEditLayoutSelector = (props) => {
  const {
    orientation,
    showEditable,
    setOrientation,
    wrap,
    setWrap,
    tooltipTitle,
    level,
    setHighlightLevel,
  } = props

  return (
    <Box
      sx={{ border: "1px dotted #ccc" }}
      onMouseOver={() => {
        if (showEditable) {
          setHighlightLevel(level)
        }
      }}
      onMouseOut={() => {
        if (showEditable) {
          setHighlightLevel(undefined)
        }
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="caption" sx={{ marginLeft: "3px" }}>
          {level + 1}
        </Typography>
        <Tooltip title={tooltipTitle}>
          <ToggleButtonGroup
            sx={{ marginLeft: "10px" }}
            variant="outlined"
            exclusive
            size="small"
            aria-label="outlined button group"
            value={orientation}
            onChange={(event, newVal) => {
              setOrientation(newVal)
            }}
          >
            <ToggleButton value="row">
              <SwapHorizIcon />
            </ToggleButton>
            <ToggleButton value="column">
              <SwapVertIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Tooltip>
        <Tooltip title="Wrap">
          <SquareIconButton
            onClick={() => setWrap(wrap === "wrap" ? "nowrap" : "wrap")}
            sx={{
              backgroundColor:
                wrap === "wrap" ? colors.grey[200] : "transparent",
            }}
          >
            <WrapTextIcon />
          </SquareIconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ModelEditLayoutSelector
