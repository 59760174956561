import {
    Box,
    Button,
    Chip,
    colors,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import CheckIcon from "@mui/icons-material/Check"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useMemo } from "react"
import _ from "lodash"
import * as palette from "./symbols/palette"
import { getPropColor, getUniqueProps } from "../pages/services/modelEditServices"
import { useHistory } from "react-router-dom"
import { styled } from "@mui/material/styles"

const elementStyles = {
    elementName: {
        fontWeight: "bold",
    },
}

const ModelEditElement = (props) => {
    const {
        item,
        showRender,
        parentRef,
        selectedItemId,
        setSelectedItemId,
        setShowEditElement,
        onMouseDown,
        onMouseUp,
        setCoords,
        layout = { orientation: "row" },
        highlightProp,
        setHighlightProp,
        showProps,
        hiddenProps,
        showUsage,
        applyShaders,
        applyShadersToChips = false,
        shaders,
        currentShader,
        hiddenElementTypes,
        quicksetProps,
        views,
        handleToggleProp,
        duplicateItems,
        drawRequestTime,
        currentView,
        handleSelectItem,
        //handleCreateDescription,
        //handleExpandItem,
        // Can be 'none', 'indicator', or 'show'
        showDocs = "none",
        elementsWithProjects,
        waitingElementIds,
        isShowGenerationPanel,
    } = props

    const DEFAULT_ELEMENT_WIDTH = 140

    const defaultElementProps = {
        x: 0,
        y: 0,
        height: 70,
        width: DEFAULT_ELEMENT_WIDTH,
    }

    const elementRef = useRef()

    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState(null)

    const [elementColor, setElementColor] = useState("")

    const [highlightProps, setHighlightProps] = useState({})

    const defaultChipColor = useMemo(
        () => (applyShadersToChips ? colors.grey[200] : colors.grey[400]),
        [applyShadersToChips]
    )

    const borderProps = useMemo(() => {
        return elementsWithProjects.find((x) => x.name === item.name)
            ? { borderColor: colors.pink[300], borderWidth: 3 }
            : undefined
    }, [elementsWithProjects, item.name])

    const descStyle = {
        fontSize: "0.7rem",
        maxWidth: "140px",
        lineHeight: "0.9rem",
    }

    const boxProps =
        layout.length > 0 && layout[0].orientation === "row"
            ? {
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
              }
            : { display: "flex", flexDirection: "column" }

    const chipBoxProps = {
        display: "flex",
        gap: "3px",
        flexWrap: "wrap",
        maxWidth: 200,
        marginBottom: "5px",
        marginTop: "5px",
        alignItems: "center",
    }

    const elementBoxProps = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "5px",
        cursor: "hand",
    }

    const getItemDuplicates = (item, views) => {
        if (item === undefined) {
            return []
        }

        const duplicates = _.flatten(
            views.map((view) =>
                view.elements
                    .filter(
                        (element) =>
                            element.name.toLowerCase() === item.name.toLowerCase() &&
                            element.type === item.type &&
                            element.id !== item.id
                    )
                    .map((element) => ({ viewId: view.id, ...element }))
            )
        )

        return duplicates
    }

    // Check if this element name and type appears in any other views, and if so what views

    // const otherViews = useMemo(() => {
    //     const testName = "Smart Grid and Microgrid Development"
    //     if (item?.name === testName) {
    //         const result = views
    //             .filter((view) => view.name !== currentView.name)
    //             .flatMap((view) =>
    //                 view.elements
    //                     .filter((element) => {
    //                         const match =
    //                             element.name.toLowerCase() === item.name.toLowerCase() &&
    //                             element.type === item.type &&
    //                             element.id !== item.id
    //                         if (element.name === testName) {
    //                             console.log("match?", match)
    //                         }
    //                         return match
    //                     })
    //                     .map((element) => ({
    //                         viewName: view.name,
    //                         viewId: view.id,
    //                     }))
    //             )

    //         const deduped = _.uniqBy(result, (item) => item.viewId)

    //         console.log("other views", deduped)
    //         return deduped
    //     }
    // }, [item.id, item.name, item.type, views, currentView.name])

    const appearsIn = useMemo(() => {
        const result = views
            .filter((view) => view.name !== currentView.name)
            .flatMap((view) =>
                view.elements
                    .filter(
                        (element) =>
                            element.name.toLowerCase() === item.name.toLowerCase() &&
                            element.name.trim() !== "" &&
                            element.type === item.type
                        // &&
                        //element.id !== item.id
                    )
                    .map((element) => ({
                        elementId: element.id,
                        viewName: view.name,
                        viewId: view.id,
                    }))
            )

        const deduped = _.uniqBy(result, (item) => item.viewId)

        return deduped
    }, [item.name, item.type, views, currentView.name])

    const rels = useMemo(() => {
        if (item && views) {
            // Find matching elements in other views, and find what elements they're related to in those views

            const dupes = getItemDuplicates(item, views).filter(
                (item) => item.viewId !== currentView.id
            )

            // Check the dupe elements to see what relationships they have

            const rels = dupes
                .map((dupe) => {
                    const dupeView = views.find((view) => view.id === dupe.viewId)

                    const parentElement = dupeView.elements.find((item) =>
                        item.children.find((child) => child.id === dupe.id)
                    )

                    if (parentElement) {
                        return { ...parentElement, viewId: dupe.viewId }
                    }
                    return undefined
                })
                .filter((item) => item)

            const uniqRels = _.uniqBy(rels, (item) => `${item.id}-${item.viewId}`)

            return uniqRels
        }
    }, [item.id, views, currentView.id])

    useEffect(() => {
        if (elementRef.current && item) {
            const yPosReduction = 0
            const heightReduction = 0
            const coords = {
                item: item,
                x: elementRef.current.offsetLeft,
                y: elementRef.current.offsetTop - yPosReduction,

                width: item.children.length > 0 ? parentRef.current?.offsetWidth : 140,
                // width:
                //     item.children.length > 0
                //         ? parentRef.current?.offsetWidth
                //         : DEFAULT_ELEMENT_WIDTH,
                // Height of ArchiMate element to be drawn, which is normal height (70px) if there are no children, otherwise full box height
                // to take into account the children heights and enabled the outer ArchiMate element to be drawn around them.
                height:
                    item.children.length > 0
                        ? parentRef.current?.offsetHeight - heightReduction
                        : 70,
                elementColor: elementColor,
            }
            if (coords.height > 0) {
                setCoords(coords)
            }
        }
    }, [item.name, drawRequestTime])

    useEffect(() => {
        if (selectedItemId !== "" && selectedItemId === item.id) {
            setHighlightProps({ backgroundColor: colors.teal["A200"] })
            return
        }
        if (
            selectedItemId !== "" &&
            duplicateItems.find(
                (duplicateItem) =>
                    duplicateItem.name.toLowerCase() === item.name.toLowerCase() &&
                    duplicateItem.type === item.type
            )
        ) {
            setHighlightProps({ backgroundColor: colors.teal["A100"] })
            return
        }
        if (applyShaders) {
            if (currentShader) {
                const itemProp = item.props.find(
                    (prop) => prop.name.toLowerCase() === currentShader.property.toLowerCase()
                )

                if (itemProp) {
                    const propValue = item.props
                        .find((prop) => prop.name === itemProp.name)
                        ?.value.toString()
                    setElementColor(
                        currentShader.config?.colors?.find(
                            (items) => items.value?.toString() === propValue
                        )?.color || colors.grey[200]
                    )
                    setHighlightProps({})
                    return
                }
                setElementColor(colors.grey[200])
                setHighlightProps({})

                return
            }
        }
        setElementColor(undefined)
        setHighlightProps({})
    }, [
        currentShader,
        applyShaders,
        item.name,
        item.type,
        item.id,
        item.props,
        selectedItemId,
        duplicateItems,
    ])

    const getMenuItems = (item, views, onClose) => {
        const props = getUniqueProps(item.type, views).filter(
            (p) => p.name !== "" && p.value !== ""
        )

        const propGroups = _.groupBy(props, "name")

        const menuItems = Object.keys(propGroups).flatMap((propName) => {
            const propValues = propGroups[propName]
            return [
                <ListSubheader key={`${propName}-header`}>{propName}</ListSubheader>,
                ...getPropertyMenuItems(propValues, onClose, handleToggleProp),
            ]
        })

        return menuItems
    }

    const getPropertyMenuItems = (props, onClose, handleToggleProp) => {
        return props.map((p) => {
            const prop = item.props.find(
                (prop) => prop.name === p.name && prop.value?.toString() === p.value?.toString()
            )

            const insetProps = {}
            if (prop === undefined) {
                insetProps.inset = true
            }
            return (
                <MenuItem
                    key={`${p.name}-${p.value}`}
                    onClick={(e) => {
                        handleToggleProp(item, p)

                        // Call onClose if control key not pressed
                        if (!e.ctrlKey) {
                            onClose()
                        }
                    }}
                >
                    {prop && (
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                    )}
                    <ListItemText {...insetProps} primary={p.value} />
                </MenuItem>
            )
        })
    }

    const getDocumentationWidth = (item) => {
        if (layout.length >= 2 && layout[1].orientation === "row") {
            const result = Math.max(item.children.length * 140, 140)
            // const result = Math.max(
            //     item.children?.length * DEFAULT_ELEMENT_WIDTH,
            //     DEFAULT_ELEMENT_WIDTH
            // )
            if (isNaN(result)) {
                return DEFAULT_ELEMENT_WIDTH
            }
            return result
        } else {
            // Add some padding
            return DEFAULT_ELEMENT_WIDTH
            //return DEFAULT_ELEMENT_WIDTH + 30
        }
    }

    return (
        <Box
            onClick={(e) => {
                const id = item.id === selectedItemId ? undefined : item.id
                setSelectedItemId(id)
                e.stopPropagation()
            }}
            sx={{ display: "flex", flexDirection: "column", flex: 1 }}
        >
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                <ListItemText
                    sx={{ marginLeft: "15px" }}
                    primary={
                        <Typography sx={{ color: "text.primary", fontWeight: "bold" }}>
                            Set Tags
                        </Typography>
                    }
                    secondary={<Typography variant="caption">Hold ctrl to multi-select</Typography>}
                />

                {Boolean(anchorEl) && getMenuItems(item, views, () => setAnchorEl(null))}
            </Menu>

            <Box ref={elementRef} sx={{ ...boxProps, ...highlightProps }}>
                {hiddenElementTypes.includes(item.type) && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "hand",
                        }}
                    >
                        <Typography variant="caption" sx={elementStyles.elementName}>
                            {item.name}
                        </Typography>
                        {quicksetProps && (
                            <IconButton
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                    e.stopPropagation()
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )}
                    </Box>
                )}
                {item.symbol && !hiddenElementTypes.includes(item.type) && (
                    <Box sx={elementBoxProps}>
                        <svg
                            height={item.children.length === 0 ? 70 : showRender ? 30 : 70}
                            width={140}
                            viewBox={"0 0 142 72"}
                            onMouseDown={(e) => {
                                e.stopPropagation()
                                onMouseDown(item)
                            }}
                            onMouseUp={(e) => {
                                e.stopPropagation()
                                onMouseUp(item)
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                // This prevents elements moving by just clicking on them
                                onMouseDown(null)
                            }}
                        >
                            {item.symbol && (
                                <item.symbol
                                    {...defaultElementProps}
                                    label={`${item.name}`}
                                    showLabel={true}
                                    showTooltips={true}
                                    showDocumentationIndicator={showDocs === "indicator"}
                                    documentation={
                                        item.description && showDocs === "indicator"
                                            ? [item.description]
                                            : []
                                    }
                                    highlight={elementColor !== undefined && elementColor !== ""}
                                    highlightColor={elementColor}
                                    handleDoubleClickItem={({ e }) => {
                                        e.stopPropagation()
                                        console.log("double click", { itemid: item.id })
                                        setSelectedItemId(item.id)
                                        setShowEditElement(true)
                                    }}
                                    handleClickItem={({ e }) => {
                                        if (selectedItemId === item.id) {
                                            setSelectedItemId(undefined)
                                            setShowEditElement(false)
                                        } else {
                                            setSelectedItemId(item.id)
                                            if (e.altKey) {
                                                setShowEditElement(true)
                                            }
                                        }
                                    }}
                                    {...borderProps}
                                />
                            )}
                        </svg>
                        {quicksetProps && (
                            <IconButton
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                }}
                                disabled={isShowGenerationPanel}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>

            {showDocs === "show" && (
                // <Box sx={{ paddingLeft: '5px', maxWidth: `${getDocumentationWidth(item)+30}px` }}>
                <Box sx={{ maxWidth: `${getDocumentationWidth(item)}px` }}>
                    <Stack direction="column" gap={1}>
                        <Typography
                            variant="caption"
                            sx={{ ...descStyle, maxWidth: elementRef?.current?.clientWidth }}
                        >
                            {item.description}
                        </Typography>
                        {item.props
                            .filter((prop) => prop?.reason !== "")
                            .filter((prop) => !hiddenProps.includes(prop.name.toLowerCase()))
                            .filter((prop) => prop.reason)
                            .map((prop) => (
                                <Typography
                                    key={`${prop.name}-${prop.value}`}
                                    variant="caption"
                                    sx={{
                                        ...descStyle,
                                        maxWidth: elementRef?.current?.clientWidth,
                                    }}
                                >
                                    <b>{prop.name}</b>: {prop.reason}
                                </Typography>
                            ))}
                    </Stack>
                </Box>
            )}
            {showDocs && waitingElementIds.includes(item.id) && (
                <Box sx={{ marginLeft: "10px", marginTop: "3px" }}>
                    <WaitingForDescription width={getDocumentationWidth(item) - 20} />
                </Box>
            )}
            {elementsWithProjects
                .filter((x) => x.name === item.name)
                .map((proj) => (
                    <Box key={proj.id}>
                        <Button
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation()
                                history.push(`/project/${proj.id}`)
                            }}
                        >
                            <Typography variant="caption" sx={{ textTransform: "none" }}>
                                Open Project
                            </Typography>
                        </Button>
                    </Box>
                ))}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    flex: 1,
                    marginTop: "5px",
                }}
            >
                {showProps && (
                    <Box sx={chipBoxProps}>
                        {item.props
                            .filter((prop) => !hiddenProps.includes(prop.name.toLowerCase()))
                            .map((prop) => {
                                //TODO: this might need optimisation/caching

                                const isShadeProp =
                                    (highlightProp &&
                                        highlightProp.name.toLowerCase() ===
                                            prop.name.toLowerCase()) ||
                                    applyShadersToChips

                                return (
                                    <PropertyChip
                                        key={`${prop.name}-${prop.value}`}
                                        propcolor={
                                            (isShadeProp &&
                                                getPropColor(prop, shaders, defaultChipColor)) ||
                                            colors.grey[200]
                                        }
                                        // sx={{
                                        //     backgroundColor:
                                        //         (isShadeProp &&
                                        //             getPropColor(
                                        //                 prop,
                                        //                 shaders,
                                        //                 defaultChipColor
                                        //             )) ||
                                        //         colors.grey[200],
                                        // }}
                                        size="small"
                                        label={
                                            <Box>
                                                {prop.reason && (
                                                    <Tooltip title={prop.reason}>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{ fontSize: "11px" }}
                                                        >
                                                            {prop.name} | <b>{prop.value}</b>
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                                {!prop.reason && (
                                                    <Typography
                                                        variant="caption"
                                                        sx={{ fontSize: "11px" }}
                                                    >
                                                        {prop.name} | <b>{prop.value}</b>
                                                    </Typography>
                                                )}
                                            </Box>
                                        }
                                        color="default"
                                        onMouseOver={() => {
                                            setHighlightProp(prop)
                                        }}
                                        onMouseOut={() => {
                                            setHighlightProp(undefined)
                                        }}
                                    />
                                )
                            })}
                    </Box>
                )}
            </Box>
            {showUsage && (
                <>
                    {rels.length > 0 ||
                        (appearsIn.length > 0 && <Box sx={{ marginTop: "5px" }}></Box>)}
                    <Relationships rels={rels} views={views} handleSelectItem={handleSelectItem} />
                    {rels.length > 0 && appearsIn.length > 0 && (
                        <Divider sx={{ marginTop: "3px", marginBottom: "3px" }} />
                    )}
                    <AppearsIn appearsIn={appearsIn} handleSelectItem={handleSelectItem} />
                </>
            )}
        </Box>
    )
}

const WaitingForDescription = ({ width }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, justifyItems: "center" }}>
            <Skeleton variant="rectangular" width={width} height={10} />
            <Skeleton variant="rectangular" width={width} height={10} />
            <Skeleton variant="rectangular" width={width} height={10} />
        </Box>
    )
}

const Relationships = ({ rels, views, handleSelectItem }) => {
    return (
        rels &&
        rels.length > 0 && (
            <Box>
                <Tooltip title="What views have a relation to this element?">
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                        Relations
                    </Typography>
                </Tooltip>
                {rels.map((rel) => {
                    const viewName = views.find((v) => v.id === rel.viewId)?.name

                    return (
                        <Box
                            key={`${rel.id}-${rel.viewId}`}
                            sx={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}
                        >
                            <Typography variant="caption" sx={{ color: colors.grey[700] }}>
                                {palette.formatLabel(palette.getElementNameByIndex(rel.type))}
                            </Typography>
                            <Tooltip title={`Open ${viewName} view`}>
                                <Typography
                                    variant="caption"
                                    sx={{ color: colors.blue[500], cursor: "hand" }}
                                    onClick={(e) => {
                                        handleSelectItem(rel.id, rel.viewId)
                                        e.stopPropagation()
                                    }}
                                >
                                    {rel.name}
                                </Typography>
                            </Tooltip>
                        </Box>
                    )
                })}
            </Box>
        )
    )
}

const PropertyChip = styled(Chip)(({ propcolor, theme }) => {
    return {
        backgroundColor: propcolor,
        color: theme.palette.getContrastText(propcolor),
    }
})

const AppearsIn = ({ appearsIn, handleSelectItem }) => {
    return (
        <>
            {appearsIn && appearsIn.length > 0 && (
                <>
                    <Tooltip title="Where is this element used in other views?">
                        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                            Usage
                        </Typography>
                    </Tooltip>

                    {appearsIn.map((target) => (
                        <Box
                            key={`${target.viewId}-${target.elementId}`}
                            sx={{ display: "flex", gap: "5px" }}
                        >
                            <Typography variant="caption">View</Typography>
                            <Tooltip title={`Open ${target.viewName} view`}>
                                <Typography
                                    sx={{ color: colors.blue[500], cursor: "hand" }}
                                    variant="caption"
                                    onClick={(e) => {
                                        handleSelectItem(target.elementId, target.viewId)
                                        e.stopPropagation()
                                    }}
                                >
                                    {target.viewName}
                                </Typography>
                            </Tooltip>
                        </Box>
                    ))}
                </>
            )}
        </>
    )
}

export default ModelEditElement
